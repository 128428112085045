import Tag from "./Tag";

export default class Selection {
    id: string;
    name: string;
    tags: Tag[] = [];
    creationDate: string;

    public static from (model: any): Selection {
        const result = new Selection();
        result.id = model.id;
        result.name = model.name;

        if (model && model.tags) {
            model.tags.forEach(it => {
                result.tags.push(Tag.from(it));
            });
        }

        result.creationDate = model.creationDate;

        return result;

    }
}
