import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import Vuex from 'vuex'
import store from './store/index';
import i18n from './i18n/index';
import 'vue-instant/dist/vue-instant.css';
import Amplify from "aws-amplify";
import { environment } from "../.env.development";
import { library } from '@fortawesome/fontawesome-svg-core'
import { applyPolyfills, defineCustomElements } from '@aws-amplify/ui-components/loader';
import {
  faChevronLeft,
  faPlusCircle,
  faTimes,
  faCheck,
  faClockRotateLeft,
  faRotateRight,
  faSearch,
  faHome,
  faMap,
  faBookmark,
  faMicrochip,
  faChevronDown,
  faUserCircle,
  faArrowRight,
  faArrowLeft,
  faCloud,
  faTriangleExclamation,
  faCircleExclamation,
  faDroplet,
  faTemperatureHalf,
  faTowerBroadcast,
  faPenToSquare,
  faXmark,
  faCircleChevronLeft,
  faCircleChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueCookies from "vue-cookies";
import Notifications from 'vue-notification'

library.add(
  faChevronLeft,
  faPlusCircle,
  faTimes,
  faCheck,
  faClockRotateLeft,
  faRotateRight,
  faSearch,
  faHome,
  faMap,
  faBookmark,
  faMicrochip,
  faChevronDown,
  faUserCircle,
  faArrowRight,
  faArrowLeft,
  faCloud,
  faTriangleExclamation,
  faCircleExclamation,
  faDroplet,
  faTemperatureHalf,
  faTowerBroadcast,
  faPenToSquare,
  faXmark,
  faCircleChevronLeft,
  faCircleChevronRight,
  )

Amplify.configure(environment.amplify);

Vue.use(Vuex);
Vue.use(VueCookies);
Vue.use(Notifications);
Vue.component('font-awesome-icon', FontAwesomeIcon)

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.ignoredElements = [/amplify-\w*/];

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
  store,
  i18n
}).$mount('#serfimApp')
