import AnnotationLabel from "./AnnotationLabel";

export default class Annotation {
    type: string;
    scaleID: string;
    mode: string;
    value: number;
    borderColor: string;
    borderWidth: number;
    borderDash: number[];
    label: AnnotationLabel;
    clip = false;

    static from(value: number, color: string): Annotation {
        const result = new Annotation();
        result.type = 'line';
        result.scaleID = 'y';
        result.mode = 'horizontal';
        result.value = value;
        result.borderColor = color ?? 'grey';
        result.borderWidth = 2;
        result.borderDash = [4, 4];
        result.label = AnnotationLabel.from(value, color);

        return result;
    }

}