<template>
  <div class="menu" v-bind:class="{ expanded: expandedMenu }">
    <div class="menu-content">
      <div class="menu-actions">

        <!-- LOGO BUTTON-->
        <div class="icon menu-button" v-on:click="toggleExpandedMenu"></div>

        <!-- HOME BUTTON-->
        <div class="button-wrapper" v-bind:class="{ selected: currentRouteName == 'Home' }" v-on:click="goToHomePage">
          <div class="icon home-button"><font-awesome-icon :icon="['fas', 'home']" fixed-width /></div>
          <span class="button-label" v-if="expandedMenu">{{ $t("menu.home") }}</span>
        </div>

        <!-- MAP BUTTON-->
        <div class="button-wrapper" v-bind:class="{ selected: currentRouteName == 'Map' }" v-on:click="goToMapPage">
          <div class="icon map-button"><font-awesome-icon :icon="['fas', 'map']" fixed-width /></div>
          <span class="button-label" v-if="expandedMenu">{{ $t("menu.map") }}</span>
        </div>

        <!-- SELECTION BUTTON-->
        <div class="button-wrapper list-group" :class="{expanded: expandedMenu}" @click="expandFavoriteMenu">
          <div class="list-header">
            <div class="label">
              <div class="icon favorite-button"><font-awesome-icon :icon="['fas', 'bookmark']" fixed-width /></div>
              <span class="button-label" v-if="expandedMenu">{{ $t("menu.favorite") }}</span>
            </div>
            <font-awesome-icon :icon="['fas', 'chevron-down']" fixed-width v-if="expandedMenu" class="chevron-down"/>
          </div>
          <div class="list-content" v-if="expandedMenu && expandFavorite">
            <div class="list-item" v-for="selection in getSelections" :key="selection.id" @click="goToSelection(selection)">
              <span>{{ selection.name }}</span>
              <font-awesome-icon :icon="['fas', 'times']" @click.stop="openDeleteSelectionModal(selection)"/>
            </div>
          </div>
        </div>

        <!-- TAGS ADMIN BUTTON-->
        <div class="button-wrapper" v-on:click="goToSensorAdmin"
            v-bind:class="{ selected: currentRouteName == 'SensorAdmin' }">
          <div class="icon sensor-button"><font-awesome-icon :icon="['fas', 'microchip']" fixed-width /></div>
          <span class="button-label" v-if="expandedMenu">{{ $t("menu.sensors") }}</span>
        </div>
      </div>

      <!-- LOGOUT BUTTON-->
      <div class="button-wrapper login">
        <div class="icon menu-account-button" v-if="!expandedMenu" @click="toggleExpandedMenu"><font-awesome-icon :icon="['fas', 'user-circle']" /></div>
        <div class="icon menu-account-button" v-if="expandedMenu" @click="signOut"><font-awesome-icon :icon="['fas', 'user-circle']"/></div>
        <span class="button-label" @click="signOut" v-if="expandedMenu">{{ $t("menu.signout") }}</span>
      </div>


      <Modal
          class="tag-dialog-modal"
          v-show="showDeleteSelectionModal"
          :show-close-icon="false"
          @close="closeDeleteSelectionModal"
      >
        <template v-slot:header>
          <div class="title">
            {{ $t("selectionAdmin.tagModal.title") }}
          </div>
        </template>
        <template v-slot:body>
          <p>{{ $t("selectionAdmin.tagModal.content") }}</p>
        </template>
        <template v-slot:footer>
          <div class="cancel button button-lite no-background" @click="closeDeleteSelectionModal()">{{ $t("sensorAdmin.tagModal.cancel") }}</div>
          <div class="save button button-full button-lite" @click="validateDeleteSelectionModal()">{{ $t("sensorAdmin.tagModal.submit") }}</div>
        </template>
      </Modal>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import ClientService from '@/service/RestClientService';
import UserInfo from '@/domain/state/UserInfo';
import MeasureKey from '@/domain/state/MeasureKey';
import SearchInfo from '@/domain/state/SearchInfo';
import {Auth} from 'aws-amplify';
import Tag from "@/domain/state/Tag";
import FrontClientService from "@/service/RestFrontClientService";
import Sensor from "@/domain/state/Sensor";
import Selection from "@/domain/state/Selection";
import Modal from "@/components/Modal";

export default Vue.extend({
  name: 'MenuComponent',
  components: {
    Modal
  },
  data() {
    return {
      expandFavorite: false,
      selections: [],
      showDeleteSelectionModal: false,
      selectionToDelete: {},
      avoidNestedCall: false
    }
  },
  computed: {
    getSelections() {
      return this.selections
    },
    expandedMenu() {
      return this.$store.getters.expandedMenu;
    },


    // -------------------
    userInfo() {
      return this.$store.getters.userInfo;
    },
    currentRouteName() {
      return this.$route.name;
    },
    authorityId() {
      return this.$store.getters.authorityId;
    },
    availableMeasureKeys() {
      return this.$store.getters.availableMeasureKeys;
    }
  },
  methods: {
    toggleExpandedMenu() {
      this.$store.dispatch('toggleExpandedMenu', !this.$store.getters.expandedMenu);
    },
    goToHomePage() {
      if (this.currentRouteName != 'Home') {
        this.$store.dispatch('clearTags');
        this.$router.push('/');
      }

      this.collapseMenuIfNecessary();
    },
    goToMapPage() {
      if (this.currentRouteName != 'Map') {
        this.$store.dispatch('clearTags');
        this.$router.push('/map');
      }
      
      this.collapseMenuIfNecessary();
    },
    goToSensorAdmin() {
      if (this.currentRouteName != 'SensorAdmin') {
        this.$store.dispatch('clearTags');
        this.$router.push('/sensorAdmin');
      }
      
      this.collapseMenuIfNecessary();
    },
    goToSelection(selection) {
      this.$store.dispatch('searchForResult', new SearchInfo(selection.tags));
      if (this.currentRouteName != 'Chart') {
        this.$router.push('/chart');
      } else {
        this.$store.dispatch('refreshCharts');
      }
      this.avoidNestedCall = true;
      this.$store.dispatch('toggleExpandedMenu', false)
    },
    collapseMenuIfNecessary() {
      if (this.expandedMenu) {
        this.toggleExpandedMenu();
      }
    },
    openDeleteSelectionModal(selection) {
      this.selectionToDelete = Object.assign({}, selection);
      this.showDeleteSelectionModal = true
    },
    closeDeleteSelectionModal() {
      this.selectionToDelete = Object.assign({}, {})
      this.showDeleteSelectionModal = false
    },
    validateDeleteSelectionModal() {
      FrontClientService.deleteSelection(this.selectionToDelete).then(response => {
        this.showDeleteSelectionModal = false

        // --- Update front selections
        this.selections = this.selections.filter(selectionFiltered => {
          return selectionFiltered.id != this.selectionToDelete.id
        })

        this.selectionToDelete = Object.assign({}, {})
      })
    },

    expandFavoriteMenu() {
      if (!this.expandedMenu && !this.avoidNestedCall) {
        this.$store.dispatch('toggleExpandedMenu', true)
      }
      this.expandFavorite = !this.expandFavorite;
      this.avoidNestedCall = false;
    },
    
    toggleFavorite() {
      this.expandFavorite = !this.expandFavorite;
    },

    //------------------------------------------------------------------------

    async signOut() {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log('error signing out: ', error);
      }
    }
  },
  watch: {
    selections: {
      deep: true,
      handler() {
        this.$store.dispatch("setSelections", this.selections)
      }
    },
  },
  mounted() {
    // --- Récupération des sélections
    if(this.$store.getters.selections && this.$store.getters.selections.length) {
        this.selections = this.$store.getters.selections
      } else {

        FrontClientService.getAllSelections().then(response => {
          response.data.forEach(it => {
            this.selections.push(Selection.from(it));
          });

          // --- Send to Vuex
          this.$store.dispatch("setSelections", this.selections)
        })
      }
  },
})

</script>

<style lang="scss" scoped>
@import "../../styles/components/menuComponents";
</style>


