export function DeviceMeasurement(resultArray, measure, graphType) {
    if (!resultArray.objectToAdd.type) resultArray.objectToAdd.type = graphType

    if (!resultArray.objectToAdd.timestamp) resultArray.objectToAdd.timestamp = []
    if (!resultArray.objectToAdd.value) resultArray.objectToAdd.value = []

    // --- Récupération des données
    if (measure.time) {
        resultArray.objectToAdd.timestamp.push(Math.round(Date.parse(measure.time) / 1000))
        resultArray.objectToAdd.value.push(measure["measure_value_double"])
    }

    return resultArray
}

export function Asset(resultArray, measure, deviceMeasureTypes) {
    resultArray.objectToAdd.type = deviceMeasureTypes[measure.measure_name].type

    if (!resultArray.objectToAdd.timestamp) resultArray.objectToAdd.timestamp = []
    if (!resultArray.objectToAdd.value) resultArray.objectToAdd.value = []
    if (measure.time) {
        resultArray.objectToAdd.timestamp.push(Math.floor(Date.parse(measure.time)) / 1000)
        const measureValue = measure["measure_value_double"] ? measure["measure_value_double"] : (measure["measure_value_boolean"] == "true" ? 1 : 0)
        resultArray.objectToAdd.value.push(measureValue)
    }

    return resultArray;
}

export function ParkingSpot(resultArray, measure, graphType) {
    if (!resultArray.objectToAdd.type) resultArray.objectToAdd.type = graphType

    if (!resultArray.objectToAdd.timestamp) resultArray.objectToAdd.timestamp = []
    if (!resultArray.objectToAdd.value) resultArray.objectToAdd.value = []

    if (measure.time && measure.measure_name == "status") {
        resultArray.objectToAdd.timestamp.push(Math.floor(Date.parse(measure.time) / 1000))

        const value = measure["measure_value_varchar"] == 'free' ? 0 : 1
        resultArray.objectToAdd.value.push(value)

        // --- TODO custom label on Y axe
        resultArray.objectToAdd.hasCustomLabel = true
        resultArray.objectToAdd.customLabels = ['free', 'occupied']
    }

    return resultArray
}

export function SmartMeteringObservation(resultArray, measure, savedMeasureForDelta, graphType, unit) {
    resultArray.objectToAdd.type = graphType
    resultArray.objectToAdd.unit = unit

    if (!resultArray.objectToAdd.timestamp) resultArray.objectToAdd.timestamp = []
    resultArray.objectToAdd.timestamp.push(Math.floor(Date.parse(measure.time)) / 1000)

    if (!resultArray.objectToAdd.index) resultArray.objectToAdd.index = []
    resultArray.objectToAdd.index.push(measure["measure_value_double"])

    if (savedMeasureForDelta) {
        resultArray.objectToAdd.delta = true

        if (!resultArray.objectToAdd.value) resultArray.objectToAdd.value = []

        const delta = parseFloat(measure["measure_value_double"]) - parseFloat(savedMeasureForDelta)
        resultArray.objectToAdd.value.push(delta)
    } else {
        resultArray.objectToAdd.delta = false
    }

    return resultArray
}

export function AirQualityObserved(resultArray, measure, deviceMeasureTypes) {
    resultArray.objectToAdd.type = deviceMeasureTypes[measure.measure_name].type
    resultArray.objectToAdd.unit = deviceMeasureTypes[measure.measure_name].unit

    if (!resultArray.objectToAdd.timestamp) resultArray.objectToAdd.timestamp = []
    if (!resultArray.objectToAdd.value) resultArray.objectToAdd.value = []
    if (measure.time) {
        resultArray.objectToAdd.timestamp.push(Math.floor(Date.parse(measure.time)) / 1000)
        const measureValue = measure["measure_value_double"] ? measure["measure_value_double"] : measure["measure_value_varchar"]
        resultArray.objectToAdd.value.push(measureValue)
    }

    return resultArray
}

export function NoiseLevelObserved(resultArray, measure, deviceMeasureTypes) {
    resultArray.objectToAdd.type = deviceMeasureTypes[measure.measure_name].type;
    resultArray.objectToAdd.unit = deviceMeasureTypes[measure.measure_name].unit;

    if (!resultArray.objectToAdd.timestamp) resultArray.objectToAdd.timestamp = [];
    if (!resultArray.objectToAdd.value) resultArray.objectToAdd.value = [];
    if (measure.time) {
        resultArray.objectToAdd.timestamp.push(Math.floor(Date.parse(measure.time)) / 1000);
        const measureValue = measure["measure_value_double"];
        resultArray.objectToAdd.value.push(measureValue);
    }
    return resultArray;
}

