<template>
  <div id="map-container">
    <l-map ref="myMap"
           :zoom="6"
           :center="[47, 2]"
           :options="{zoomControl: false}"
    >
      <l-tile-layer
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
          attribution="&copy; <a href='http://osm.org/copyrighte'>OpenStreetMap</a> contributors"
      />
      <v-marker-cluster>
      </v-marker-cluster>
    </l-map>
  </div>
</template>

<script>
import {LMap, LTileLayer} from "vue2-leaflet";
import L from "leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import restService from '@/service/RestClientService';
import FrontClientService from "@/service/RestFrontClientService";
import Sensor from "@/domain/state/Sensor";
import "leaflet/dist/leaflet.css";
import "@/assets/map/MarkerCluster.css";
import "@/assets/map/MarkerCluster.Default.css";
import icon from "@/assets/map/marker-icon.png";
import iconShadow from "@/assets/map/marker-shadow.png";
import {measureTypes} from "@/service/ChartsParams";
import {Asset, AirQualityObserved, DeviceMeasurement, ParkingSpot, SmartMeteringObservation} from "@/service/SmartDataModels";
import {getRelevantDevicesMeasurements} from "@/service/Utils";
import moment from "moment";
import {DateFormat} from '@/service/Constants';

export default {
  name: "MapChart",
  props: ['sensors'],
  components: {
    LMap,
    LTileLayer,
    "v-marker-cluster": Vue2LeafletMarkerCluster
  },
  data() {
    return {
      map: undefined,
      devices: [],

      defaultIcon: L.icon({
        iconUrl: icon,
        iconSize: [26, 42],
        iconAnchor: [13, 42],
        shadowUrl: iconShadow,
        shadowSize: [41, 41],
        shadowAnchor: [13, 41]
      })
    };
  },
  watch: {
    sensors: async function () {
      if (this.sensors && this.sensors.length != 0) {
        await getRelevantDevicesMeasurements(
          this.sensors
        ).then(() => {
          this.loadPositions()
        })
      }
    }
  },
  async mounted() {
    // --- Get devices
    if (this.$store.getters.sensors && this.$store.getters.sensors.length) {
      this.devices = this.$store.getters.sensors
    } else {
      await FrontClientService.getAllDevices().then(response => {
        response.data.forEach(it => {
          this.devices.push(Sensor.from(it));
        });

        // --- Send to Vuex
        this.$store.dispatch("setSensors", this.sensors)
      })
    }
  },
  methods: {
    async loadPositions() {
      const markersCluster = new L.MarkerClusterGroup();
      const positions = [];

      await Promise.all(this.sensors.map(sensor => {
          this.createPopup(sensor, markersCluster, positions)
      }))

      this.map = this.$refs.myMap.mapObject;
      L.control.zoom({
        position: 'bottomright'
      }).addTo(this.map);

      this.map.fitBounds(new L.LatLngBounds(positions));
      this.map.addLayer(markersCluster);
    },
    createPopup(sensor, markersCluster, positions) {
      // specify popup options
      var customOptions = {
        'className' : 'map-popup',
      }

      let measures = "";
      if (sensor.measurements && sensor.measurements.length) {
        let channel = 0;
        sensor.measurements.map(measure => {
          if (!measure.alias.includes("CityLabSandbox")) {
            channel += 1
            measures += "<div class=\"popup-measure\">"
            measures += "<div class=\"popup-measure-name\">"
            measures += measure.type !== "BAR" ? measure.type : "entrée " + channel
            measures += ":</div>"
            measures += "<span class=\"popup-measure-value\">"

            if (measure.urn.includes("ParkingSpot")) {
              switch (measure.value) {
                case 0:
                  measures += "libre"
                  break;
                case 1:
                  measures += "occupée"
                  break;

                default:
                  measures += "-"
                  break;
              }
            } else {
              let unit = measure.unit ? measure.unit : ""
              measures += measure.value !== null ? measure.value + unit : "-"
            }

            measures += "</span>"
            measures += "</div>"
          }
        })
        let lastMeasureTime = Math.max(...sensor.measurements.map((e) => e.time))
        if (measures !== "") {
          measures += "<div class=\"popup-measure-date\">Date de la mesure: " + moment.unix(lastMeasureTime).lang("fr").format(DateFormat.FULL) + "</div>"
        }
      }

      if (measures === "") {
        measures = "<span style=\"color: grey\">aucune donnée</span>"
      }

      const position = new L.LatLng(sensor.latitude, sensor.longitude);
      positions.push(position);
      const marker = L.marker(position, {icon: this.defaultIcon});

      marker.bindPopup(
        "<span class=\"map-popup-title\">" + (sensor.alias ? sensor.alias : sensor.name) +
        "</span><br /><div class=\"popup-measures\">" + measures + "</div>", customOptions
      );

      markersCluster.addLayer(marker);
    }
  }
};
</script>
