import { ModelSensor } from "../model/Model";
import Tag from "./Tag";

export default class Sensor {
    id: string;
    name: string;
    tags: Tag[] = [];
    latitude?: number;
    longitude?: number;
    urn: string[] = [];
    unit: string;
    threshold?: number;
    alias?: string;

    static from(model: ModelSensor): Sensor {
        const result = new Sensor();

        if (model.id) {
            result.id = model.id;
        }
        if (model.name) {
            result.name = model.name;
        }
        if (model.urn) {
            result.urn = model.urn;
        }
        if (model.tags) {
            model.tags.forEach(tagItem => {
                const tag = Tag.from(tagItem);
                result.tags.push(tag);
            });
        }
        if (model.latitude) {
          result.latitude = model.latitude;
        }
        if (model.longitude) {
            result.longitude = model.longitude;
        }
        if (model.unit) {
            result.unit = model.unit;
        }
        if (model.threshold) {
            result.threshold = model.threshold;
        }
        if (model.alias) {
            result.alias = model.alias;
        }

        return result;
    }
}
