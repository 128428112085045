import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Search from '../views/Search.vue';
import Chart from '../views/Chart.vue';
import Map from '../views/Map.vue';
import SensorAdmin from '../views/SensorAdmin.vue';

import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';

Vue.use(VueRouter);
Vue.use(VueMaterial);

const routes: Array<RouteConfig> = [
  {
    path:'*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/chart',
    name: 'Chart',
    component: Chart
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/map',
    name: 'Map',
    component: Map
  },
  {
    path: '/sensorAdmin',
    name: 'SensorAdmin',
    component: SensorAdmin
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
