<template>
  <div class="home-menu">
    <div class="home">
      <div class="home-title heading-3">{{ $t("home.welcomeMessage") }}</div>
      <div class="home-first-row">
        <AlertsComponent />
        <div class="home-map heading-4">
          <div class="home-map-title">
            {{ $t("home.coverZone") }}
          </div>
          <MapChartComponent v-if="shouldDisplayMap" :sensors=sensors />
          <div v-if="!shouldDisplayMap" class="full-map-image" v-on:click="openMap"></div>
        </div>
      </div>
      <div class="selections">
        <div class="selection-title heading-4">
          {{ $t("home.selection.title") }}
        </div>
        <SelectionWrapperComponent />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import AlertsComponent from '@/components/alert/AlertsComponent.vue';
import MapChartComponent from '@/components/chart/MapChartComponent.vue';
import SelectionWrapperComponent from '@/components/selection/SelectionWrapperComponent.vue';
import MenuComponent from '@/components/menu/MenuComponent.vue';
import restService from '@/service/RestClientService';
import FrontClientService from "@/service/RestFrontClientService";
import Sensor from "@/domain/state/Sensor";
import Selection from "@/domain/state/Selection";

export default Vue.extend({
  name: 'Home',
  data() {
    return {
      selections: [],
      selectionsCookie: [],
      sensors: [],
    }
  },
  components: {
    AlertsComponent,
    MapChartComponent,
    SelectionWrapperComponent
  },
  computed: {
    shouldDisplayMap() {
      return window.innerWidth > 800;
    },
    authorityId() {
      return this.$store.getters.authorityId;
    },
    getSelections() {
      return this.selections
    }
  },
  methods: {
    openMap() {
      this.$router.push({name: 'Map', params: { positions: this.positions }});
    },
    goToSelection() {
      this.$router.push('selectionAdmin');
    }
  },
  mounted() {
    // --- Récupération des sélections
    if(this.$store.getters.selections && this.$store.getters.selections.length) {
      this.selections = this.$store.getters.selections
    } else {
      FrontClientService.getAllSelections().then(response => {
        response.data.forEach(it => {
          this.selections.push(Selection.from(it));
        });

        // --- Send to Vuex
        this.$store.dispatch("setSelections", this.selections)
      })
    }

    // --- Get sensors
    if (this.$store.getters.sensors && this.$store.getters.sensors.length) {
      this.sensors = this.$store.getters.sensors
    } else {
      FrontClientService.getAllDevices().then(response => {
        response.data.forEach(it => {
          this.sensors.push(Sensor.from(it));
        });

        // --- Send to Vuex
        this.$store.dispatch("setSensors", this.sensors)
      })
    }
  }
})

</script>

<style lang="scss" scoped>
@import "../styles/views/home.scss";
</style>


