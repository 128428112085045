export class Model {
    timestamp: number[];
    value: number[];
    index: number[];
    type: string;
    unit: string;
    tags: string[];
    stats: ModelStat;
    threshold: number;
    hasCustomLabel: boolean;
    customLabels: string[];

    public static from(timestamp?: number[], value?: number[], index?: number[], type?: string, unit?: string, tags?: string[], stats?: ModelStat, threshold?: number, hasCustomLabel?: boolean, customLabels?: string[]) {
        const result = new Model();

        if (timestamp) {
            result.timestamp = timestamp;
        }
        if (value) {
            result.value = value;
        }
        if (index) {
            result.index = index;
        }
        if (type) {
            result.type = type;
        }
        if (unit) {
            result.unit = unit;
        }
        if (tags) {
            result.tags = tags;
        }
        if (stats) {
            result.stats = stats;
        }
        if (threshold) {
            result.threshold = threshold;
        }
        if (hasCustomLabel) {
            result.hasCustomLabel = hasCustomLabel;
        }
        if (customLabels) {
            result.customLabels = customLabels;
        }

        return result;
    }
}

export class ModelTag {
    id: string;
    name: string;
    type: ModelTagType;
    parentId: string;
    children: ModelTag[];
    editable: boolean;
    sdm: string[];

    public static from(id? : string, name?: string, type?: ModelTagType, parentId?: string, children?: ModelTag[], editable?: boolean, sdm?: string[]) {
        const result = new ModelTag();

        if (id) {
            result.id = id;
        }
        if (name) {
            result.name = name;
        }
        if (type) {
            result.type = type;
        }
        if (parentId) {
            result.parentId = parentId;
        }
        if (parentId) {
            result.parentId = parentId;
        }
        if (children) {
            result.children = children;
        }
        if (editable) {
            result.editable = editable;
        }
        if (sdm) {
            result.sdm = sdm;
        }

        return result;
    }
}
export class ModelSensor {
    id: string;
    name: string;
    urn: string[] = [];
    tags: ModelTag[];
    latitude?: number; // only for saving sensor
    longitude?: number; // only for saving sensor
    unit: string;
    threshold: number;
    alias: string;

    public static from(id? : string, name?: string, tags?: ModelTag[], latitude?: number, longitude?: number, alias?: string) {
        const result = new ModelSensor();

        if (id) {
            result.id = id;
        }
        if (name) {
            result.name = name;
        }
        if (tags) {
            result.tags = tags;
        }
        if (latitude) {
            result.latitude = latitude;
        }
        if (longitude) {
            result.longitude = longitude;
        }
        if (alias) {
            result.alias = alias;
        }

        return result;
    }
}

export enum ModelTagType {
    LOCATION = 'LOCATION',
    ZONE = 'ZONE',
    UNIVERSE = 'UNIVERSE',
}

export class ModelStat {
    min: number;
    max: number;
    avg: number;

    public static from(min?: number, max?: number, avg?: number) {
        const result = new ModelStat();

        if (min) {
            result.min = min;
        }
        if (max) {
            result.max = max;
        }
        if (avg) {
            result.avg = avg;
        }
        return result;
    }
}

export class ModelSensorAlert {
    sensorId: string;
    name: string;
    tags: ModelTag[];
    lastMeasureDate: moment.Moment;
    alias: string;

    static from(sensorId?: string, tags?: ModelTag[], lastMeasureDate?: moment.Moment, alias?: string) {
        const result = new ModelSensorAlert();

        if (sensorId) {
            result.sensorId = sensorId;
        }
        if (tags) {
            result.tags = tags;
        }
        if (lastMeasureDate) {
            result.lastMeasureDate = lastMeasureDate;
        }
        if (alias) {
            result.alias = alias;
        }

        return result;
    }
}

export class ModelUserInfo {
    selections: ModelSelection[];
    authorityId: number;

    static from(selections?: ModelSelection[], authorityId?: number) {
        const result = new ModelUserInfo();

        if (selections) {
            result.selections = selections;
        }

        if (authorityId) {
            result.authorityId = authorityId;
        }

        return result;
    }
}

export class ModelMeasureKey {
    key: string;
    label: string;
    type: ModelGraphType;

    static from(key?: string, label?: string, type?: ModelGraphType) {
        const result = new ModelMeasureKey();

        if (key) {
           result.key = key;
        }
        if (label) {
            result.label = label;
        }
        if (type) {
            result.type = type;
        }
        return result;
    }
}
enum ModelGraphType {
    BAR = 'BAR',
    LINE = 'LINE'
}

export class ModelSelection {
    id: string;
    name: string;
    tags: ModelTag[];
    keys: ModelMeasureKey[]
    type: ModelSelectionType;
    creationdate: string;
    userid: string;

    static from(id?: string, name?: string, tags?: ModelTag[], keys?: ModelMeasureKey[], type?: ModelSelectionType, date?: string, userId?: string) {
        const result = new ModelSelection();

        if (id) {
            result.id = id;
        }
        if (name) {
            result.name = name;
        }
        if (tags) {
            result.tags = tags;
        }
        if (keys) {
            result.keys = keys;
        }
        if (type) {
            result.type = type;
        }
      if (date) {
        result.creationdate = date;
      }
      if (userId) {
        result.userid = userId;
      }

        return result;
    }
}

export enum ModelSelectionType {
    FAVORITE = 'FAVORITE',
    HISTORY = 'HISTORY'
}

export class PositionModel {
    name: string;
    latitude: number;
    longitude: number;
}

export class MeasureModel {
	date: moment.Moment;
	value: number;
	key: string;
	unit: string;
	tags: ModelTag[];
  sensorid: string;
  hasError: boolean;

}
