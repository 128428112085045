import ChartData from "./ChartData";
import ChartOptions from "./ChartOptions";

export default class ChartInfo {

    uuid: string;
    chartData: ChartData;
    chartOptions: ChartOptions = ChartOptions.from();
    chartTitle: string;
    channel: string;

    static from(uuid?: string, chartData?: ChartData, annotationValue?: number, chartTitle?: string, channel?: string) {
        const result: ChartInfo = new ChartInfo();
        if (uuid) {
            result.uuid = uuid;
        }

        if (chartData) {
            result.chartData = chartData;

            const graphScales = ChartData.getScalesMinMax(chartData, annotationValue)
            if(graphScales) {
                ChartOptions.setGraphScales(result.chartOptions, graphScales)
            }

            if (chartData.datasets[0].type === "bar") {
                ChartOptions.barChartOptions(result.chartOptions);
            }
        }

        if (annotationValue && chartData) {
            ChartOptions.addAnnotation(result.chartOptions, annotationValue, chartData.datasets);
        } else if (annotationValue) {
            ChartOptions.addAnnotation(result.chartOptions, annotationValue);
        }

        if (chartTitle) {
            result.chartTitle = chartTitle;
        }
        if (channel) {
            result.channel = channel;
        }

        return result;
    }

    getChartTitle() {
        let chartTitle = "";
        if (this.chartData && this.chartData.datasets) {
            if(this.chartData.datasets.length == 1) {
                const title = this.chartData.datasets[0].label
                const AirQualityDataTypes = ["PM","PM2.5","PM10","Co2"]
                
                if (title) {
                    // --- Check if sensor alias exist
                    if (this.chartData.sensorAlias) {
                        chartTitle = this.chartData.sensorAlias
                    }

                    if (AirQualityDataTypes.includes(this.chartData.datasets[0].dataType)) {
                        chartTitle += " - " + "Qualité de l'air"
                    }
                    if (title.split(" - ")[1]) {
                        chartTitle += " - " + title.split(" - ")[1]
                    }
                } else {
                    chartTitle = 'Titre inconnu';
                }
            }
        } else {
            chartTitle = this.chartTitle
        }
        return chartTitle;
    }
}
