<template>
  <date-range-picker v-model="range"
                     :autoApply=true
                     :locale-data="localeData"
                     :single-date-picker=rangeMode
                     :ranges=false >
    <!--    input slot (new slot syntax)-->
    <template #input="picker">
      <span class='icon-cursor-left' v-on:click.stop="previousRange"><font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width /></span> <span>{{ picker.startDate | date }} - {{ picker.endDate | date }} <span class='icon-calendar'></span></span> <span class='icon-cursor-right' v-on:click.stop="nextRange"><font-awesome-icon :icon="['fas', 'arrow-right']" fixed-width /></span>
    </template>
    <!--    date slot-->
    <template #date="data">
      <span class="small">{{ data.date | dateCell }}</span>
    </template>
    <!--    ranges (new slot syntax) -->
    <template #ranges="ranges">
      <div class="ranges">
        <ul>
          <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)">
            <b>{{ name }}</b> <small class="text-muted">{{ range[0].toDateString() }} -
            {{ range[1].toDateString() }}</small>
          </li>
        </ul>
      </div>
    </template>
  </date-range-picker>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import { Locale, DaysOfWeek, MonthsOfYear, RangeAction, DateFormat } from '@/service/Constants';

export default {
    name: "DateRange",
    components: { DateRangePicker },
    computed: {
        range: {
            // getter
            get: function () {
                return this.$store.getters.range;
            },
            // setter
            set: function (newRange) {
                this.$store.dispatch('selectRange', newRange);
            }
        }
    },
    data: () => ({
        rangeMode: 'range',
        localeData: { 
            firstDay: 1,
            daysOfWeek: DaysOfWeek[Locale],
            monthNames: MonthsOfYear[Locale],
        }
    }),
    filters: {
        dateCell (value) {
            let dt = new Date(value)

            return dt.getDate()
        },
        date (val) {
            return val ? moment(val).locale(Locale).format(DateFormat.LITTERAL) : ''
        }
    },
    methods: {
        previousRange() {
            this.$store.dispatch('changeRange', RangeAction.PREVIOUS);
        },
        nextRange() {
            this.$store.dispatch('changeRange', RangeAction.NEXT);
        }
    }
}
</script>

<style lang="scss">
  @import "../../styles/views/chart.scss";
</style>