<script>
import { Line, mixins } from 'vue-chartjs';
import annotation from 'chartjs-plugin-annotation';

const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['chartData', 'uuid'],
  computed: {
    chartInfo() {
      return this.$store.getters.chartById(this.uuid);
    }
  },
  mounted () {
    this.renderChart(this.chartInfo.chartData, this.chartInfo.chartOptions);
  }
}
</script>
