<template>
    <div class="title" v-html="chartLabel"></div>
</template>
<script>

export default {
    name: "Stat",
    props: {
      chartLabel: {type: String, default: 'Titre inconnu'}
    },
}
</script>
