import Tag from "./Tag";
import Selection from "../selection/Selection";

export default class UserInfo {
    selections: Selection[] = [];
    authorityId = 1;

    static from(model: any): UserInfo {
        const result = new UserInfo();

        if (model.selections) {
            model.selections.forEach(it => {
                result.selections.push(Selection.from(it));
            })
        }

        return result;
    }
}
