<template>
    <div class="measure" v-if="measure && shouldDisplay" :class="measure.hasError ? 'invalid' : ''">
      <div class="measure-value heading-5">
        <MeasureIconComponent :measureKey="measure.measureKey" :hasError="measure.hasError" />
          <span>{{ Math.round(measure.value) }}</span>
      </div>

      <div class="measure-name body-extra-small">
        {{ measureName }} ({{ measure.measureUnit }})
      </div>
      <div v-if="selectedLocation" class="measure-location body-small">
        {{ lastLocationTag }}
      </div>
      <div v-if="!selectedLocation" class="measure-location body-small">
        {{ $t("measures.sensor") }}
        #{{measure.sensorId}}
      </div>
    </div>
</template>

<script>
import MeasureIconComponent from '@/components/alert/measure/MeasureIconComponent.vue';
import Measure from '@/domain/state/Measure';

export default {
    name: "MeasureComponent",
    components: {
        MeasureIconComponent
    },
    props: ['measure', 'selectedLocation', 'selectedMeasure'],
    computed: {
        lastLocationTag() {
            return this.measure.getLastLocationTag();
        },
        shouldDisplay() {
            return this.isLocationOk() && this.isMeasureOk();
        },
        availableMeasureKeys() {
            return this.$store.getters.availableMeasureKeys;
        },
        measureName() {
          const res = this.availableMeasureKeys.find(it => it.key == this.measure.measureKey);
          if (res) {
            return res.label;
          }
          return '';
        }
    },
    methods: {
        isLocationOk() {
            if (this.selectedLocation == 'Tous' || this.selectedLocation == undefined) {
                return true;
            }
            return this.lastLocationTag == this.selectedLocation;
        },
        isMeasureOk() {
            if (this.selectedMeasure == 'Toutes' || this.selectedMeasure == undefined) {
                return true;
            }
            return Measure.getMeasureLabelByMeasureKey(this.availableMeasureKeys, this.measure.measureKey.toUpperCase()) == this.selectedMeasure;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../styles/components/alertComponent.scss";
</style>