import moment, { Moment } from 'moment';

export default class DateRange {
    startDate: Date;
    endDate: Date;

    constructor(start: Moment, end: Moment) {
        this.startDate = start["_d"];
        this.endDate = end["_d"];
    }
}