













import Vue from 'vue';

export default Vue.extend({
  name: 'Footer',
  computed: {
      selectedChartUuids() {
          return this.$store.getters.selectedChartUuids;
      }
  },
  methods: {
      cancel() {
          this.$store.dispatch('removeSelectedCharts');
      },
      merge() {
          window.scrollTo(0, 0);
          this.$store.dispatch('mergeSelectedCharts');
      }
  }
})

