import moment from "moment"
import { ModelSensorAlert } from "../model/Model";
import Tag from "./Tag";

export default class SensorAlert {
    sensorId: string;
    name: string;
    tags: Tag[] = [];
    lastMeasureDate: moment.Moment;
    alias: string;

    static from(model: ModelSensorAlert): SensorAlert {
        const result = new SensorAlert();

        result.sensorId = model.sensorId;
        result.name = model.name;

        if (model && model.tags) {
            model.tags.forEach(it => {
                result.tags.push(Tag.from(it));
            });
        }

        result.lastMeasureDate = model.lastMeasureDate;
        result.alias = model.alias;

        return result;
    }
}
