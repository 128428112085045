import ChartDataset from "./ChartDataset";
import { Model } from "../model/Model";
import moment from 'moment';
import Stats from "../stats/Stats";
import { DateFormat } from '@/service/Constants';
import GraphType from "../state/GraphType";

export default class ChartData {

    labels: string[] = [];
    datasets: ChartDataset[] = [];
    stats: Stats = new Stats();
    hasCustomLabel = false;
    customLabels: string[] = [];
    sensorAlias: string;

    static copy(chartData: ChartData): ChartData {
        const result = new ChartData();
        const labels: string[] = []

        for(let i = 0; i < chartData.labels.length; i++){
            labels.push(
                chartData.labels[i].split("\n")[0]
            )
        }

        result.labels = labels;
        result.datasets = chartData.datasets;
        return result;
    }

    static from(model: Model | null, graphType: GraphType | undefined, title: string, sensorAlias: string): ChartData {
        const result: ChartData = new ChartData();

        if (model) {
            const dataset: ChartDataset = ChartDataset.from(model, title, undefined, graphType);
            result.datasets = [dataset];


            const labels = model.timestamp.map((it, i) => {
                let label = "Date de la mesure : " + moment.unix(it).format(DateFormat.SMALL)

                label += "\n\n"
                label += graphType === "BAR" ? "Consommation : " : "Valeur : " 
                label += (dataset.data[i] ?? "") + " " + (model.unit ?? "");
                
                if (model.index) {
                    const index = Math.round(model.index[i])
                    label +=  "\n" + "Index : " + (index ?? "") + " " + (model.unit ?? "")
                }
                return label
            });

            result.labels = labels

            if (model.stats) {
                result.stats = Stats.from(model);
            }
    
            if (model.hasCustomLabel) {
                result.hasCustomLabel = model.hasCustomLabel;
            }
            if (model.customLabels) {
                result.customLabels = model.customLabels;
            }
        }
        
        if (sensorAlias) {
            result.sensorAlias = sensorAlias;
        }

        return result;
    }

    static getScalesMinMax(chartData: ChartData, threshold?: number) {
        if(chartData.datasets[0].type.toUpperCase() == GraphType.LINE) {
            const minMaxChartData = {
                min: Math.min(...chartData.datasets[0].data),
                max: Math.max(...chartData.datasets[0].data)
            }

            // --- Utilisation du seuil si il se trouve en dehors du range des valeurs
            if (threshold) {
                minMaxChartData.max = (threshold > minMaxChartData.max ? threshold : minMaxChartData.max)
                minMaxChartData.min = (threshold < minMaxChartData.min ? threshold : minMaxChartData.min)
            }

            // --- Mise en place de l'échelle min à -10% du minimum des données
            if(minMaxChartData.min > 0 && ((minMaxChartData.min - (minMaxChartData.max - minMaxChartData.min) * 0.1) <= 0.99)) {
                minMaxChartData.min = 0
            } else {
                minMaxChartData.min = Math.floor(minMaxChartData.min - (minMaxChartData.max - minMaxChartData.min) * 0.1)
            }

            // --- Mise en place de l'échelle max à +10% du maximum des données
            minMaxChartData.max = Math.ceil(minMaxChartData.max + (minMaxChartData.max - minMaxChartData.min) * 0.1)

            return minMaxChartData
        }
        return false
    }
}

