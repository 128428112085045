

































import Vue from 'vue';
import ChartComponent from '@/components/chart/ChartComponent.vue';
import ChartTitleComponent from '@/components/chart/ChartTitleComponent.vue';
import ChartSelectComponent from '@/components/chart/ChartSelectComponent.vue';
import StatsComponent from '@/components/stat/StatsComponent.vue';
import {DefaultChartStyle, RangeAction, ChartColourBounds} from '@/service/Constants';

export default Vue.extend({
  name: 'ChartWithStats',
  props: ['chartInfo'],
  components: {
    ChartComponent,
    ChartTitleComponent,
    StatsComponent,
    ChartSelectComponent,
  },
  data() {
    return {
      style: DefaultChartStyle,
      waitingForEventAdd: false,
      listening: false,
      legendDisplay: false,
      legendLabels: [{min: 0, max: 0}],
    }
  },
  methods: {
    showLegend() {
      if (this.$props.chartInfo.chartData && this.$props.chartInfo.chartData.datasets && this.$props.chartInfo.chartData.datasets.length) {
        this.$props.chartInfo.chartData.datasets.map((dataset: { coloredGraphTypes: string[]; dataType: string; }) => {
          if (dataset.coloredGraphTypes.includes(dataset.dataType)) {
            this.legendLabels = ChartColourBounds[dataset.dataType]
            this.legendDisplay = true;
          }
        })
      }
    },
    getPreviousRange(uuid: string) {
      this.$store.dispatch('changeRange', RangeAction.PREVIOUS);
    },
    getNextRange(uuid: string) {
      this.$store.dispatch('changeRange', RangeAction.NEXT);
    },
  },
  mounted() {
    this.showLegend()
  }
})

