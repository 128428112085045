import Vue from 'vue';
import VueI18n from 'vue-i18n';
import fr from './lang/fr';
import en from './lang/en';
import { Locale } from '@/service/Constants';

Vue.use(VueI18n)

export default new VueI18n({
    locale: Locale, // set locale
    messages: {
        en,
        fr 
    }
  });