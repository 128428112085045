<template>
  <div class="alerts heading-4">
    <div class="alerts-title">
      {{ $t("home.alerts.title") }}
    </div>
    <div class="alerts-content">
      <!-- Measure Alerts -->
      <div class="measure-alerts">
        <div class="measure-alerts-title heading-5">
          {{ $t("home.alerts.measureAlerts", {size: measures.length}) }}
        </div>
        <div class="measure-alerts-content">
          <div class="loader" v-if="showLoaderMeasure">
            <Loader /> <!--For Measurement Alert-->
          </div>
          <div class="measure-container" v-else v-for="(measure, index) of measures" :key='index'>
            <MeasureComponent :measure=measure />
          </div>
          <p v-if="!showLoaderMeasure && !measures.length">{{ $t("home.alerts.noMeasureAlert") }}</p>
        </div>
      </div>
      <!-- Sensor Alerts -->
      <div class="sensor-alerts">
        <div class="sensor-alerts-title heading-5">
          {{ $t("home.alerts.sensorAlerts", {size: sensors.length}) }}
        </div>
        <div class="sensor-alerts-content">
          <div class="loader" v-if="showLoaderSensor">
            <Loader /> <!--For Sensor Alert-->
          </div>
          <div v-else v-for="sensor of sensors" :key='sensor.sensorId'>
            <SensorComponent :sensor=sensor />
          </div>
          <p class="no-sensor" v-if="!showLoaderSensor && sensors.length == 0">{{ $t("home.alerts.noSensorAlert") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import ClientService from '@/service/RestClientService';
import MeasureComponent from '@/components/alert/measure/MeasureComponent.vue';
import SensorComponent from '@/components/alert/SensorComponent.vue';
import Measure from '@/domain/state/Measure';
import Sensor from '@/domain/state/SensorAlert';
import Loader from "@/components/Loader";

export default Vue.extend({
  name: 'Home',
  data() {
    return {
      measures: [],
      sensors: [],
      showLoaderMeasure: true,
      showLoaderSensor: true,
    }
  },
  components: {
    Loader,
    MeasureComponent,
    SensorComponent
  },
  computed: {
    authorityId() {
      return this.$store.getters.authorityId;
    }
  },
  mounted() {
    ClientService.getMeasureAlerts(this.authorityId).then(response => {
      response.data.map(it => this.measures.push(Measure.from(it, true)));
      this.showLoaderMeasure = false
    });
    ClientService.getSensorAlerts(this.authorityId).then(response => {
      response.data.map(it => this.sensors.push(Sensor.from(it)));
      // --- Fake data start
      this.sensors = this.$store.getters.sensors.slice(0, 2);
      // --- Fake data end
      this.showLoaderSensor = false
    });

  }
})
      
</script>

<style lang="scss" scoped>
@import "../../styles/components/alertComponent.scss";
</style>