<template>
  <span class="loader"></span>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style lang="scss" scoped>
@import "src/styles/components/_loaderComponents.scss";
</style>