
const transparency = 0.7;
const Colours = [
    `rgba(0, 88, 163, ${transparency})`, `rgba(214, 48, 49, ${transparency})`, `rgba(9, 132, 227, ${transparency})`, `rgba(211, 84, 0, ${transparency})`,
    `rgba(241, 196, 15, ${transparency})`, `rgba(39, 174, 96, ${transparency})`, `rgba(108, 92, 231, ${transparency})`, `rgba(41, 128, 185, ${transparency})`,
    `rgba(231, 76, 60, ${transparency})`, `rgba(0, 184, 148, ${transparency})`, `rgba(253, 121, 168, ${transparency})`, `rgba(37, 204, 247, ${transparency})`,
    `rgba(253, 114, 114, ${transparency})`, `rgba(84, 160, 255, ${transparency})`, `rgba(0, 210, 211, ${transparency})`, `rgba(26, 188, 156, ${transparency})`,
    `rgba(46, 204, 113, ${transparency})`, `rgba(52, 152, 219, ${transparency})`, `rgba(155, 89, 182, ${transparency})`, `rgba(52, 73, 94, ${transparency})`,
    `rgba(142, 68, 173, ${transparency})`, `rgba(44, 62, 80, ${transparency})`, `rgba(230, 126, 34, ${transparency})`, `rgba(236, 240, 241, ${transparency})`,
    `rgba(149, 165, 166, ${transparency})`, `rgba(243, 156, 18, ${transparency})`, `rgba(192, 57, 43, ${transparency})`, `rgba(189, 195, 199, ${transparency})`,
    `rgba(127, 140, 141, ${transparency})`, `rgba(85, 239, 196, ${transparency})`, `rgba(129, 236, 236, ${transparency})`, `rgba(116, 185, 255, ${transparency})`,
    `rgba(162, 155, 254, ${transparency})`, `rgba(223, 230, 233, ${transparency})`, `rgba(0, 206, 201, ${transparency})`, `rgba(255, 234, 167, ${transparency})`,
    `rgba(250, 177, 160, ${transparency})`, `rgba(255, 118, 117, ${transparency})`, `rgba(253, 203, 110, ${transparency})`,`rgba(22, 160, 133, ${transparency})`,
];

const AxisIds = [
    'A', 'B', 'C', 'D',
    'E', 'F', 'G', 'H',
    'I', 'J', 'K', 'L',
    'M', 'N', 'O', 'P',
    'Q', 'R', 'S', 'T',
    'U', 'V', 'W', 'X',
    'Y', 'Z'
];

const Locale = 'fr';

const DaysOfWeek = {
    fr: ['Di','Lu','Ma','Me','Je','Ve','Sa'],
    en: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
}

const MonthsOfYear = {
    fr: ['Janvier', 'Février', 'Mars','Avril','Mai','Juin','Juillet','Aout','Septembre','Octobre','Novembre','Décembre'],
    en: ['January', 'February', 'March ', 'April', 'May ', 'June', 'July ', 'August', 'September ', 'October', 'November ', 'December']
}

const DefaultChartStyle = {
    height: '250px',
    width: '100%'
}

enum RangeAction {
    PREVIOUS,
    NEXT
}

enum DateFormat {
    LITTERAL = 'DD MMMM YYYY',
    SMALL = 'DD-MM-YYYY HH:mm',
    FULL = 'DD/MM/yyyy HH:mm:ss'
}

const ChartColours = {
    defaultGrey: '#CCCCCC',
    defaultGrey2: '#CCCCCC55',
    defaultBlue: '#3378dedd',
    borderBlue: '#5398dedd',
    backgroundBlue: '#c3d8ee66',
    good: '#50f0e6',
    healthy: '#50f0e6',
    acceptable: '#50ccaa',
    fair: '#50ccaa',
    moderate: '#f0e641',
    average: '#aad021',
    poor: '#ff5050',
    low: '#ff5050',
    veryPoor: '#960032',
    veryLow: '#960032',
    extremelyPoor: '#7d2181',
    dangerous: '#000000'
}

const ChartColourBounds = {
    "PM2.5": {
        good: {
            min: 0,
            max: 10
        },
        fair: {
            min: 10,
            max: 20
        },
        moderate: {
            min: 20,
            max: 25
        },
        poor: {
            min: 25,
            max: 50
        },
        veryPoor: {
            min: 50,
            max: 75
        },
        extremelyPoor: {
            min: 75,
            max: 800
        },
    },
    "PM10": {
        good: {
            min: 0,
            max: 20
        },
        fair: {
            min: 20,
            max: 35
        },
        moderate: {
            min: 35,
            max: 50
        },
        poor: {
            min: 50,
            max: 100
        },
        veryPoor: {
            min: 100,
            max: 150
        },
        extremelyPoor: {
            min: 150,
            max: 1200
        },
    },
    "PM": {
        good: {
            min: 0,
            max: 10
        },
        fair: {
            min: 10,
            max: 20
        },
        moderate: {
            min: 20,
            max: 50
        },
        poor: {
            min: 50,
            max: 100
        },
        veryPoor: {
            min: 100,
            max: 150
        },
        extremelyPoor: {
            min: 150,
            max: 1200
        },
    },
    "Co2": {
        healthy: {
            min: 0,
            max: 350
        },
        acceptable: {
            min: 350,
            max: 700
        },
        average: {
            min: 700,
            max: 1000
        },
        moderate: {
            min: 1000,
            max: 1200
        },
        low: {
            min: 1200,
            max: 1750
        },
        veryLow: {
            min: 1750,
            max: 5000
        },
        dangerous: {
            min: 5000,
            max: 300000
        },
    },
}

export { Colours, Locale, DaysOfWeek, MonthsOfYear, DefaultChartStyle, RangeAction, DateFormat, AxisIds, ChartColours, ChartColourBounds }
