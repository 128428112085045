<template>
  <div class="sensor-admin">
    <div class="back-home-link" @click="backToHome">
      <font-awesome-icon :icon="['fas', 'chevron-left']" /> <p>{{ $t("backToHome") }}</p>
    </div>

    <div class="page-title">
      {{ $t("sensorAdmin.header") }}
    </div>

    <div class="loader-container">
      <div class="loader" v-if="showLoader">
        <Loader />
      </div>
    </div>

    <div class="sensors-list" v-if="!showLoader">
      <div class="sensor-wrapper" v-for="(sensor, index) of sensors" :key='index'>
        <div class="sensor-infos">
          <div class="sensor-name">
            <button @click="editAlias(index)">
              <font-awesome-icon :icon="['fas', 'pen-to-square']" />
            </button>
            <span v-if="sensor.editingAlias">
              <input :id="sensor.name" type="text" :value="getAlias(sensor)" @keyup="updateSensorAlias($event, sensor)" @keyup.enter="updateAlias(sensor, index)">
              <button @click="resetAlias(index)">
                <font-awesome-icon :icon="['fas', 'xmark']" />
              </button>
                <button @click="updateAlias(sensor, index)">
                <font-awesome-icon :icon="['fas', 'check']" />
              </button>
            </span>
            <span v-else>
              {{ sensor.alias ? sensor.alias : sensor.name }}
            </span>
          </div>
          <div class="sensor-tags">
            <template v-if="sensor.tags.length">
              <BadgeComponent
                  v-for="tag in sensor.tags"
                  :key='tag.id'
                  :tag=tag
                  :deletable='tag.editable'
                  :tag-parent-name='getTagName(tag.parentId)'
                  @removeTag="removeTag(sensor.id, tag)"
              />
            </template>
            <template v-else>
              <p class="no-tag">{{ $t("sensorAdmin.noTag") }}</p>
            </template>
            <div class="add-tag" @click="openTagDialog(sensor)">
              <font-awesome-icon :icon="['fas', 'plus-circle']" size="sm" fixed-width/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal
        class="tag-dialog-modal"
        v-show="showTagDialog"
        :show-close-icon="false"
        @close="closeDialog"
    >
      <template v-slot:header>
        <div class="title">
          {{ $t("sensorAdmin.tagModal.title") }}
        </div>
      </template>
      <template v-slot:body>
        <div class="tab-container" v-if="!showDeleteTagDialog">
          <div class="tab" :class="{ active: showAddTagForm }" @click="changeTabModal('addTag')">
            {{ $t("sensorAdmin.tagModal.addTag") }}
          </div>
          <div class="tab" :class="{ active: showCreateTagForm }" @click="changeTabModal('createTag')">
            {{ $t("sensorAdmin.tagModal.createTag") }}
          </div>
        </div>
        <template v-if="showAddTagForm">
          <div class="content add-tag">
            <div class="selecting-tag">
              <p>{{ $t("sensorAdmin.tagModal.selectedTag") }}</p>
              <div class="sensor-tag-container">
                <template v-if="selectingTags.length">
                  <BadgeComponent
                      v-for="tag in selectingTags"
                      :key='"selected-"+tag.id'
                      :tag=tag
                      :deletable='tag.editable'
                      :is-selecting='true'
                      :tag-parent-name='getTagName(tag.parentId)'
                      @removeTag='removeSelectedTag'
                  />
                </template>
                <template v-else>
                  <p class="no-tag">{{ $t("sensorAdmin.tagModal.noTagSelected") }}</p>
                </template>
              </div>
            </div>
            <div class="tag-list">
              <p>{{ $t("sensorAdmin.tagModal.availableTag") }}</p>
              <div class="sensor-tag-container">
                <template v-if="availableTags.length">
                  <BadgeComponent
                      v-for="tag in availableTags"
                      :key='"selecting-"+tag.id'
                      :tag=tag
                      :deletable='tag.editable'
                      :is-selecting='true'
                      :tag-parent-name='getTagName(tag.parentId)'
                      :deleting-tags='true'
                      @deleteTag='deleteTagEvent(tag)'
                      @addTag='addSelectedTag(tag)'
                  />
                </template>
                <template v-else>
                  <p class="no-tag">{{ $t("sensorAdmin.tagModal.noTagAvailable") }}</p>
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-if="showCreateTagForm">
          <div class="content form-tag">
            <div class="input-container">
              <p>Parent</p>
              <select name="tag-type" id="tag-type" v-model="newTag.parent">
                <option value="null">---</option>
                <option v-for="locationTag in getLocationTags" :key="locationTag.id" :value="locationTag">
                  {{ locationTag.name }}
                </option>
              </select>
            </div>
            <div class="input-container">
             <p>Nom du tag</p>
              <input type="text" name="tag-name" id="tag-name" v-model="newTag.name">
            </div>
          </div>
        </template>
        <template v-if="showDeleteTagDialog">
          <div class="delete-tag">
            <p>{{ $t("sensorAdmin.tagModal.deleteTag") }}</p>
            <p class="info">{{ $t("sensorAdmin.tagModal.deleteTagInfo") }}</p>
            <div class="action-container">
              <div class="cancel button" @click="deleteTagAction('cancel')">
                <font-awesome-icon :icon="['fas', 'times']"/>
                {{ $t("sensorAdmin.tagModal.no") }}
              </div>
              <div class="submit button" @click="deleteTagAction('submit')">
                <font-awesome-icon :icon="['fas', 'check']"/>
                {{ $t("sensorAdmin.tagModal.yes") }}
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-slot:footer v-if="!showDeleteTagDialog">
        <template>
          <div class="cancel button button-lite no-background"
               @click="cancelTag(showAddTagForm ? 'addTag' : 'createTag')">{{ $t("sensorAdmin.tagModal.cancel") }}
          </div>
          <div class="save button button-full button-lite"
               @click="validateTag(selectedSensor, showAddTagForm ? 'addTag' : 'createTag')">
            {{ $t("sensorAdmin.tagModal.submit") }}
          </div>
        </template>
      </template>
    </Modal>
  </div>
</template>

<script>
import Vue from 'vue';
import FrontClientService from '@/service/RestFrontClientService';
import ClientService from '@/service/RestClientService';
import Sensor from '@/domain/state/Sensor';
import Tag from '@/domain/state/Tag';
import BadgeComponent from '@/components/tag/BadgeComponent.vue';
import Modal from '@/components/Modal.vue';
import Loader from "@/components/Loader";

const TYPE_LOCATION = "LOCATION"

export default Vue.extend({
  name: 'SensorAdmin',

  components: {
    Loader,
    BadgeComponent,
    Modal
  },

  data() {
    return {
      tags: [],
      sensors: [],
      showTagDialog: false,
      showSensorDialog: false,
      showPositionDialog: false,
      showAddTagForm: true,
      showCreateTagForm: false,
      showDeleteTagDialog: false,
      selectedSensor: undefined,
      selectingTags: [],
      availableTags: [],
      oldAlias: "",
      tagToDelete: undefined,
      newTag: {
        type: null,
        name: '',
        editable: true,
        parent: null
      },
      showLoader: true,
    }
  },

  computed: {
    /**
     * Return location tags
     * @returns {*[]}
     */
    getLocationTags() {
      return this.tags.filter(tag => tag.type == "LOCATION")
    },
  },
  methods: {
    /**
     * Back to home action
     */
    backToHome() {
      this.$router.push('/');
    },
    /**
     * Delete tag from device
     * @param sensorId
     * @param tagId
     */
    removeTag(sensorId, tag) {
      const sensorToEdit = this.sensors.find(it => it.id == sensorId);
      const tagToRemove = sensorToEdit.tags.find(it => it.id == tag.id);
      sensorToEdit.tags.splice(sensorToEdit.tags.indexOf(tagToRemove), 1);

      // --- Remove child tags
      if (tag.childrenId && tag.childrenId.length) {
        tag.childrenId.forEach(childId => {
          if (sensorToEdit.tags.filter(e => e.id === childId).length > 0) {
            this.removeTag(sensorId, this.tags.find(findTag => findTag.id == childId))
          }
        })
      }

      // --- Update device in DB
      FrontClientService.postDevice(sensorToEdit)
    },
    /**
     * Switch between modal tabs
     * @param tab
     */
    changeTabModal(tab) {
      switch (tab) {
        case 'addTag':
          this.showAddTagForm = true;
          this.showCreateTagForm = false;
          this.showDeleteTagDialog = false;
          break;
        case 'createTag':
          this.showAddTagForm = false;
          this.showCreateTagForm = true;
          this.showDeleteTagDialog = false;
          break;
        case 'deleteTag':
          this.showAddTagForm = false;
          this.showCreateTagForm = false;
          this.showDeleteTagDialog = true;
          break;
        default:
          break;
      }
    },
    /**
     * Add tag to selecting tags
     * @param tag
     */
    addSelectedTag(tag) {
      this.selectingTags.push(tag)
      this.availableTags = this.availableTags.filter(availableTag => (tag.id != availableTag.id && tag.editable))
    },
    /**
     * Delete tag from selecting tags
     * @param tagId
     */
    removeSelectedTag(tag) {
      this.selectingTags = this.selectingTags.filter(selectingTag => selectingTag.id != tag.id)
      this.availableTags.push(tag)

      // --- Remove child tags
      if (tag.childrenId && tag.childrenId.length) {
        tag.childrenId.forEach(childId => {
          if (this.selectingTags.filter(e => e.id === childId).length > 0) {
            this.removeSelectedTag(this.tags.find(findTag => findTag.id == childId))
          }
        })
      }
    },
    /**
     * Open Tag modal
     * @param sensor
     */
    openTagDialog(sensor) {
      this.selectedSensor = sensor;
      this.selectingTags = [...sensor.tags];
      this.availableTags = this.diffTags(this.tags, sensor.tags)
      this.showTagDialog = true;
    },
    /**
     * List of tags that are not in the sensor
     * @param tags
     * @param sensorTags
     */
    diffTags(tags, sensorTags) {
      return tags.filter(({id: idTag}) => !sensorTags.some(({id: idSensorTag}) => idTag === idSensorTag)).filter(tag => tag.editable)
    },
    /**
     * Add tag into dynamoDB or in tag selection
     * @param sensor
     * @param type
     */
    validateTag(sensor, type) {
      // --- Add tag to device
      if (type == 'addTag') {
        sensor.tags = this.selectingTags;

        // --- Update device with tag in DB
        FrontClientService.postDevice(sensor).then(response => {
          if (response.status == 200) {
            this.showTagDialog = false;
          }
        })
      }
      // --- Create tag and back to add tag panel
      if (type == 'createTag') {
        let params = Object.assign({}, this.newTag)
        delete params.parent

        // --- Get last max ID
        const tagsIds = this.tags.map(tag => {
          return tag.id
        })
        const maxIdTag = Math.max.apply(null, tagsIds)
        params.id = (maxIdTag + 1).toString();

        params.type = TYPE_LOCATION

        // --- Add parentID
        if (this.newTag.parent && Object.keys(this.newTag.parent).length) {
          params.parentId = this.newTag.parent.id

          // --- Update parent tag (add child ID)
          let parentTag = this.tags.find(tag => tag.id == params.parentId)
          if (!parentTag.childrenId) parentTag.childrenId = [params.id]
          else parentTag.childrenId.push(params.id)

          // --- Update parent in DB
          FrontClientService.postTag(parentTag)
        }

        // --- Add tag in DB
        FrontClientService.postTag(params).then(response => {
          this.newTag = {
            type: null,
            name: '',
            editable: true,
            parent: null
          }

          // --- Update tags selections
          this.tags.push(Tag.from(params))
          this.selectingTags.push(params)

          // --- Update parents tag selection
          if (params.parentId && !this.selectingTags.find(tag => tag.id == params.parentId)) {
            this.selectingTags.push(this.tags.find(tag => tag.id == params.parentId))
          }

          // --- Order selecting tags
          this.selectingTags.sort((itemA, itemB) => (parseInt(itemA.id) > parseInt(itemB.id)) ? 1 : ((parseInt(itemB.id) > parseInt(itemA.id)) ? -1 : 0))

          // --- Change tab pane
          this.changeTabModal('addTag')
        })
      }
    },
    /**
     * Close Tag modal
     * @param type
     */
    cancelTag(type) {
      this.showTagDialog = false;
      if (type == 'addTag') {
        this.selectedSensor = undefined
        this.selectingTags = []
        this.availableTags = []
      }
    },
    /**
     * Close Tag modal TODO
     */
    closeDialog() {
      this.selectedSensor = undefined;
      this.showDialog = false;
    },
    /**
     * Return tag name
     * @param tagId
     * @returns {*|boolean}
     */
    getTagName(tagId) {
      let tagFind = this.tags.find(tag => tag.id == tagId)
      return tagFind ? tagFind.name : false
    },
    /**
     * Get tag to delete
     * @param tag
     */
    deleteTagEvent(tag) {
      this.tagToDelete = Object.assign({}, tag)
      this.changeTabModal('deleteTag')
    },
    /**
     * Switch action
     * @param type
     */
    deleteTagAction(type) {
      if (type == "submit") {
        if (this.tagToDelete) {
          let params = Object.assign({}, this.tagToDelete)

          // --- Delete tag and children
          this.deleteTag(params)
        }
      }
      if (type == "cancel") {
        this.tagToDelete = undefined
        this.changeTabModal('addTag')
      }
    },
    deleteTag(tag) {
      // --- Delete tag in DB
      FrontClientService.deleteTag(tag)

      // --- Delete tag from device in DB
      this.sensors.forEach(sensor => {
        let filteredTags = sensor.tags.filter(tagFiltered => {
          return tagFiltered.id != tag.id
        })
        sensor.tags = filteredTags

        // --- Send updated device in DB
        FrontClientService.postDevice(sensor)
      })

      // --- Update front tags
      this.tags = this.tags.filter(tagFiltered => {
        return tagFiltered.id != tag.id
      })
      this.availableTags = this.availableTags.filter(tagFiltered => {
        return tagFiltered.id != tag.id
      })

      // --- Delete children tag
      if (tag.childrenId && tag.childrenId.length) {
        tag.childrenId.forEach(childId => {
          let childrenTag = this.tags.find(findTag => findTag.id == childId)
          this.deleteTag(childrenTag)
        })
      }

      // --- Return to tag selection pane
      this.changeTabModal('addTag')
    },
    editAlias(index) {
      let sensors_temp = [...this.sensors];
      this.sensors = [];

      sensors_temp.map(sensor => {
        if (sensor.id !== sensors_temp[index].id) {
          sensor.editingAlias = false
        } else {
          this.oldAlias = sensor.alias ? sensor.alias : sensor.name
        }
      })
      
      sensors_temp[index].editingAlias = !sensors_temp[index].editingAlias
      
      this.$nextTick(() => {
        this.sensors = [...sensors_temp];
      });
    },
    resetAlias(index) {
      let sensors_temp = [...this.sensors];
      this.sensors = [];

      sensors_temp[index].alias = this.oldAlias
      sensors_temp[index].editingAlias = !sensors_temp[index].editingAlias

      this.$nextTick(() => {
        this.sensors = [...sensors_temp];
      });
    },
    updateAlias(sensor, index) {
      ClientService.updateAlias({id: sensor.id, alias: sensor.alias}).then(() => {
          this.editAlias(index)
        }
      )
    },
    getAlias(sensor) {
      return sensor.alias ? sensor.alias : sensor.name
    },
    updateSensorAlias(event, sensor) {
      sensor.alias = event.target.value
    }
  },
  watch: {
    tags: {
      deep: true,
      handler() {
        this.$store.dispatch("setTags", this.tags)
      }
    },
    sensors: {
      deep: true,
      handler() {
        this.$store.dispatch("setSensors", this.sensors)
      }
    }
  },
  mounted() {
    // --- Get sensors
    if (this.$store.getters.sensors && this.$store.getters.sensors.length) {
      this.sensors = this.$store.getters.sensors
      this.showLoader = false
    } else {
      FrontClientService.getAllDevices().then(response => {
        response.data.forEach(it => {
          this.sensors.push(Sensor.from(it));
        });

        // --- Send to Vuex
        this.$store.dispatch("setSensors", this.sensors)
        this.showLoader = false
      })
    }

    this.sensors.forEach(sensor => {
      sensor.editingAlias = false;
    })

    // --- Get tags
    if (this.$store.getters.tags && this.$store.getters.tags.length) {
      this.tags = this.$store.getters.tags
      this.showLoader = false
    } else {
      FrontClientService.getAllTags().then(response => {
        response.data.forEach(it => {
          this.tags.push(Tag.from(it));
        });

        // --- Send to Vuex
        this.$store.dispatch("setTags", this.tags)
        this.showLoader = false
      });
    }
  }
})
</script>

<style lang="scss" scoped>
@import "../styles/views/sensorAdmin.scss";
</style>
