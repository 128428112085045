import { ChartColours, ChartColourBounds, DateFormat } from '@/service/Constants';
import moment from 'moment';

export default class ChartDataset {
    label: string;
    timestamp: string[];
    data: number[];
    index: number[];
    fill = false;
    backgroundColor = ChartColours.defaultGrey2;
    borderColor = ChartColours.defaultGrey;
    pointBackgroundColor = [ChartColours.defaultGrey];
    pointBorderColor = [ChartColours.defaultGrey];
    coloredGraphTypes = ["PM", "PM2.5", "PM10", "Co2"]
    borderWidth = 2;
    pointRadius = 2;
    yAxisID = '';
    type = 'line';
    dataType: string;
    threshold: number;

    static from(model : any, title: string, fill?: boolean, type?: string): ChartDataset {
        const result = new ChartDataset();

        if (model.type) {
            result.dataType = model.type;
        }
        if (model.timestamp) {
            result.timestamp = model.timestamp
            
        }
        if (model.unit) {
            result.label = title + ' (' + model.unit + ')'
        } else {
            result.label = title
        }
        if (model.value) {
            const dataRounded: number[] = []
            
            model.value.map((d: any) => {
                if (type === "BAR" && d < 0) {
                    d = 0
                }
                dataRounded.push(Math.round((parseFloat(d) + Number.EPSILON) * 100) / 100);
            })
            result.data = dataRounded
            
            let color: string[] = [ChartColours.defaultGrey]

            // --- If colored graph
            if (result.coloredGraphTypes.includes(model.type)) {
                color = []
                result.fill = true

                result.data.map(value => {
                    // --- Set color on graph depending on value
                    Object.keys(ChartColourBounds[model.type]).map(range => {
    
                        // --- Check range of type
                        if (value >= ChartColourBounds[model.type][range].min 
                          && value < ChartColourBounds[model.type][range].max) {
                            color.push(ChartColours[range])
                        }
                    })
                })
            } else {
                color = []
                result.data.map(() => {
                    color.push(ChartColours.defaultBlue)
                })
                result.borderColor = ChartColours.borderBlue
                result.backgroundColor = ChartColours.backgroundBlue
            }

            result.pointBackgroundColor = color
            result.pointBorderColor = color
        }
        if (model.index) {
            result.index = model.index;
        }
        if (fill) {
            result.fill = fill;
        }
        if (type) {
            result.type = type.toLowerCase()
        }

        return result;
    }

    static copy(chartDataset: ChartDataset): ChartDataset {
        const result = new ChartDataset();

        result.label = chartDataset.label;
        result.data = chartDataset.data;
        result.type = chartDataset.type;

        return result;
    }
}