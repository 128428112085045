import ChartData from "@/domain/chart/ChartData";
import ChartInfo from "@/domain/chart/ChartInfo";
import Duration from '@/domain/state/Duration';
import moment from "moment";
import State from "@/domain/state/State";
import DateRange from '@/domain/state/DateRange';
import { AxisIds, RangeAction, Colours } from "@/service/Constants";
import { v4 as uuid } from 'uuid';
import ChartDataset from "@/domain/chart/ChartDataset";
import ChartOptions from "@/domain/chart/ChartOptions";
import Tag from "@/domain/state/Tag";
import UserInfo from "@/domain/state/UserInfo";
import MeasureKey from "@/domain/state/MeasureKey";
import Sensor from "@/domain/state/Sensor";
import Selection from "@/domain/state/Selection";
import {getDevicesMeasurements, getTypesToSelect, filterDeviceURN } from "@/service/Utils";
import Vue from 'vue';

const mutations = {
  // --- Set sensors to store
  setSensors(state: State, sensors: Sensor[]) {
    state.sensors = sensors
  },
  // --- Set tags to store
  setTags(state: State, tags: Tag[]) {
    state.tags = tags
  },
  // --- Set selections to store
  setSelections(state: State, selections: Selection[]) {
    state.selections = selections
  },
  // --- Clear tags
  clearTags(state: State) {
    state.tags = [];
    state.selectedTags = [];
  },
  // --- Clear tags
  setCharts(state: State, charts: ChartInfo[]) {
    state.charts = charts;
  },
  
  // --- Clear tags
  setLoader(state: State, value: boolean) {
    state.loader = value;
  },

// -------------------------------------------------------------------------
  // change selected duration
  selectDuration(state: State, durationValue: keyof typeof Duration) {
    const duration = Duration[durationValue];
    state.selectedDuration = duration;
    if (state.selectedDuration == Duration.DAY) {
      state.startDate = moment().startOf('day');
    } else if (state.selectedDuration == Duration.WEEK) {
      state.startDate = moment().startOf('day').subtract(1, 'weeks');
    } else if (state.selectedDuration == Duration.MONTH) {
      state.startDate = moment().startOf('day').subtract(1, 'months');
    } else if (state.selectedDuration == Duration.YEAR) {
      state.startDate = moment().startOf('day').subtract(3, 'months');
    }
    state.endDate = moment().endOf('day');
  },

  // update chart data
  async updateCharts(state: State) {
    state.charts = []
    state.loader = true
    const mergedDatasets: any = []

    state.charts.map(chart => {
      if (chart.chartData && chart.chartData.datasets.length > 1) {
        mergedDatasets.push(chart.chartData.datasets.map(d => d.label))
      }
    })

    await getDevicesMeasurements(
      state.sensors,
      state.charts,
      state.startDate,
      state.endDate,
      {
        typesToSelect: getTypesToSelect(state.selectedTags),
        devicesURNs: filterDeviceURN(state)
      },
      mergedDatasets
    ).then(() => {

      // --- Sort by title
      let charts = state.charts.sort((a, b) => a.getChartTitle().localeCompare(b.getChartTitle()))

      // --- Sort by merged charts first
      charts = charts.sort((a, b) => !a.chartData || !b.chartData ? -1 : Number(b.chartData.datasets.length > 1) - Number(a.chartData.datasets.length > 1))
      
      // --- Sort by charts with data first
      const chartsNoData = charts.filter(chart => !chart.chartData)
      charts = charts.filter(chart => chart.chartData)
      state.charts = charts.concat(chartsNoData)
      
      state.loader = false
    }).catch((error) => {
      state.loader = false
      Vue.notify({
        title: 'Erreur',
        type: 'error',
        text: 'Erreur lors de la récupération des données',
      })
    })
  },

  // select new range of dates
  selectRange(state: State, range: DateRange) {
    state.startDate = moment(range.startDate).startOf('day');
    state.endDate = moment(range.endDate).endOf('day');

    const duration = Math.round(moment.duration(state.endDate.diff(state.startDate)).asDays());
    if (duration == 1) {
      state.selectedDuration = Duration.DAY;
    } else if (duration == 8) {
      state.selectedDuration = Duration.WEEK;
    } else if (duration == 31) {
      state.selectedDuration = Duration.MONTH;
    } else if (duration == 93) {
      state.selectedDuration = Duration.YEAR;
    } else {
      state.selectedDuration = Duration.NONE;
    }

  },

  // select new range
  changeRange(state: State, action: RangeAction) {
    const duration = Math.round(moment.duration(state.endDate.diff(state.startDate)).asDays());

    if (action == RangeAction.PREVIOUS) {

      state.startDate = moment(state.startDate.subtract(duration, 'days'));
      state.endDate = moment(state.endDate.subtract(duration, 'days'));

    } else if (action == RangeAction.NEXT) {

      const tempDate = moment(state.endDate)
      const futureEndDate = tempDate.add(duration, 'days');

      if (futureEndDate.isSameOrBefore(moment(), 'day')) {
        state.startDate = moment(state.startDate.add(duration, 'days'));
        state.endDate = futureEndDate
      }
    }
  },

  // select or unselect chart
  toggleChartSelection(state: State, uuid: string) {
    const index = state.selectedChartUuid.indexOf(uuid)

    if (index < 0) {
      state.selectedChartUuid.push(uuid);
    } else {
      state.selectedChartUuid.splice(index, 1);
    }
  },

  // empty selected charts list
  removeSelectedCharts(state: State) {
    state.selectedChartUuid = [];
  },

  // merge selected charts
  mergeSelectedCharts(state: State) {
    const charts = state.charts.filter(it => state.selectedChartUuid.includes(it.uuid));
    const mergedChartInfo = new ChartInfo();
    mergedChartInfo.uuid = uuid();
    mergedChartInfo.chartData = ChartData.copy(charts[0].chartData);
    mergedChartInfo.chartOptions = ChartOptions.copy(charts[0].chartOptions);

    // clear scale
    mergedChartInfo.chartOptions.scales.yAxes = [];
    mergedChartInfo.chartData.datasets = [];
    let colourIndex = 0;
    charts.forEach(it => {
      it.chartData.datasets.forEach(dataset => {
        const newDataSet = ChartDataset.copy(dataset);

        newDataSet.backgroundColor = Colours[colourIndex];
        newDataSet.borderColor = Colours[colourIndex];
        newDataSet.pointBackgroundColor = [Colours[colourIndex]];
        newDataSet.yAxisID = AxisIds[colourIndex];

        mergedChartInfo.chartData.datasets.push(newDataSet);

        const minMaxChartData = {
          min: Math.min(...newDataSet.data),
          max: Math.max(...newDataSet.data)
        } 

        // add scale
        ChartOptions.addYAxis(mergedChartInfo.chartOptions, colourIndex, minMaxChartData);

        colourIndex++;
      });
    });

    state.charts.unshift(mergedChartInfo);
  },

  // toggle collapse menu
  toggleExpandedMenu(state: State, expanded: boolean) {
    state.expandedMenu = expanded;
  },

  // update tag list and consult result
  searchForResult(state: State, tags: Tag[]) {
    state.selectedTags = tags;
  },

  // update tag list and consult result
  emptyChartList(state: State) {
    state.charts = [];
  },

  // update userInfo
  setUserInfo(state: State, userInfo: UserInfo) {
    state.userInfo = userInfo;
  },

  populateMeasureKeys(state: State, keys: MeasureKey[]) {
    state.availableMeasureKeys = Object.assign([], keys);
  },

  updateSelectedMeasureKeys(state: State, keys: MeasureKey[]) {
    state.selectedMeasureKeys = Object.assign([], keys);
  }
}

export default mutations;
