<template>
  <div class="selection-component" @click="search">
    <template v-if="!selection">
      <div class="add-button">
        <span class="add-icon">+</span>
        <p>{{ $t("home.selection.new") }}</p>
      </div>
    </template>
    <template v-else>
      <div class="selection">
        
        <div class="date"><font-awesome-icon :icon="['fas', 'clock-rotate-left']" fixed-width /><span class="selection-date-text">{{selection.date}}</span></div>
        <div class="tags">
          <BadgeComponent
              v-for="tag in limitTags(selection.tags)"
              :key='tag.id'
              :tag=tag
              :deletable='false'
              :is-selecting='false'
          />
          <p class="others" v-if="selection.tags.length - limitTags(selection.tags).length">et {{ selection.tags.length - limitTags(selection.tags).length }} autre(s) tag(s)</p>
        </div>
        <div class="search-icon">
          <font-awesome-icon :icon="['fas', 'search']" fixed-width />
        </div>
      </div>
    </template>
  </div>

</template>

<script>
import Vue from 'vue';
import SearchInfo from '@/domain/state/SearchInfo';
import BadgeComponent from '@/components/tag/BadgeComponent.vue';

export default Vue.extend({
  name: 'SelectionComponent',
  components: {
    BadgeComponent
  },
  props: ['selection', 'type'],
  methods: {
    /**
     * Launch search
     */
    search() {
        if (!this.selection) {
            this.$router.push('/search');
        } else {
          this.$store.dispatch('searchForResult', new SearchInfo(this.selection.tags));
          this.$router.push('/chart');
        }
    },
    /**
     * Return tag name
     * @param tagId
     * @returns {*|boolean}
     */
    getTagName(tagId) {
      let tagFind = this.tags.find(tag => tag.id == tagId)
      return tagFind ? tagFind.name : false
    },
    /**
     * Limit tags displayed (limit by 4)
     * @param tags
     * @returns {*}
     */
    limitTags(tags) {
      return tags.slice(0, 4)
    },
  }
})

</script>

<style lang="scss">
@import "../../styles/components/selectionComponent.scss";
</style>


