import GraphType from "@/domain/state/GraphType";
import {NoiseLevelObserved} from "@/service/SmartDataModels";

const measureTypes = {
    DeviceMeasurement: {
        graphType: GraphType.BAR
    },
    Asset: {
        choc: { type: "Alerte choc", payloadEntry: "choc", graphType: GraphType.BAR },
        inclinaison_detection: { type: "Alert inclinaison", payloadEntry: "inclinaison_detection", graphType: GraphType.BAR },
        armed: { type: "Armé", payloadEntry: "armed", graphType: GraphType.BAR },
        unarmed: { type: "Non armé", payloadEntry: "unarmed", graphType: GraphType.BAR },
        arming_in_progress: { type: "En cours d'armement", payloadEntry: "arming_in_progress", graphType: GraphType.BAR },
        high_temperature: { type: "Température élevée", payloadEntry: "high_temperature", graphType: GraphType.BAR },
        battery_level: { type: "Niveau de batterie", payloadEntry: "battery_level", graphType: GraphType.LINE },
        temperature: { type: "Température", payloadEntry: "temperature", graphType: GraphType.LINE },
    },
    AirQualityObserved:
    {
        co2: { type: "Co2", unit: "ppm", payloadEntry: "co2", graphType: GraphType.LINE, stats: true },
        relativeHumidity: { type: "Humidité", unit: "% rH", payloadEntry: "relativehumidity", graphType: GraphType.LINE, stats: true },
        temperature: { type: "Température", unit: "°C", payloadEntry: "temperature", graphType: GraphType.LINE, stats: true },
        pressure: { type: "Pression", unit: "hPa", payloadEntry: "pressure", graphType: GraphType.LINE, stats: true },
        pm: { type: "PM", unit: `µg/m³`, payloadEntry: "pm", graphType: GraphType.LINE, stats: true},
        pm25: { type: "PM2.5", unit: `µg/m³`, payloadEntry: "pm25", graphType: GraphType.LINE, stats: true},
        pm10: { type: "PM10", unit: `µg/m³`, payloadEntry: "pm10", graphType: GraphType.LINE, stats: true},
    },
    SmartMeteringObservation: {
        graphType: GraphType.BAR,
        unit: {
            MTQ: "m³",
            KWH: "kWh"
        }
    },
    ParkingSpot: {
        graphType: GraphType.BAR
    },
    WasteContainer: {
        graphType: GraphType.BAR
    },
    ACMeasurement: {
        graphType: GraphType.BAR
    },
    NoiseLevelObserved: {
        LAS: { type: "LAS", unit: "dB", payloadEntry: "Niveau sonore", graphType: GraphType.LINE, stats: true },
        LAmax: { type: "LAmax", unit: "dB", payloadEntry: "Niveau sonore max", graphType: GraphType.LINE, stats: true },
    },
}

export { measureTypes }
