import axios from "axios";
import Selection from '@/domain/selection/Selection';
import Tag from "@/domain/state/Tag";
import Sensor from "@/domain/state/Sensor";
import { environment } from "../../.env.development";
import { Auth } from "aws-amplify";
import MeasureKey from "@/domain/state/MeasureKey";
import 'moment-timezone';

import moment from "moment";

async function getAuthorizationHeader() {
  return {
    headers: {
      'Authorization': 'Bearer ' + (await Auth.currentSession()).getIdToken().getJwtToken()
    }
  };
}

export default {

  /**
   * Should get Temperature data for given tags
   * @param startDate start of the period
   * @param endDate end of the period
   * @param tags tags name to filter on
   * @returns List of Model
   */
  async getGraphData(authorityId: number, startDate: moment.Moment, endDate: moment.Moment, tags: string[], measureKeys: string[]) {
    const userId = (await Auth.currentUserInfo()).attributes.sub;
    const payload = {
      startDate: startDate.unix(),
      endDate: endDate.unix(),
      tags: tags,
      measureTypes: measureKeys,
      userId : userId
    }
    const requestHeaders = await getAuthorizationHeader();
    return axios.post(environment.VUE_APP_BASE_URL + authorityId  + '/measures/search', payload, requestHeaders);
  },

  /**
   * Get list of all devices measurements
   * @param collectivityId
   * @returns List of ModelSensor
   */
  async getAllDeviceMeasurements(startDate: moment.Moment, endDate: moment.Moment, params : any) {
    const payload = await getAuthorizationHeader();
    const payloadParams = {
      params: {
        startDate: startDate.format("YYYY-MM-DD HH:mm:ss"),
        endDate: endDate.format("YYYY-MM-DD HH:mm:ss"),
        typesToSelect: params.typesToSelect,
        deviceURNs: params.devicesURNs
      }
    }
    Object.assign(payload, payloadParams)

    const result = new Promise((resolve, reject) => {
      axios.get(environment.VUE_APP_BASE_URL_V2 + 'device-measurements', payload)
      .then((response) => {

        // --- Fix timestream measure timezone
        response.data.map((measure: any) => {
          const currentTimezoneOffset = moment(measure.time).tz("Europe/Paris").utcOffset();
          measure.time = moment(measure.time).add(currentTimezoneOffset, "minutes").format("YYYY-MM-DD HH:mm:ss")
          
          return measure
        })

        resolve(response)
      })
      .catch(error => {      
        reject(error.message);
      });
    });

    return result
  },

  /**
   * Get relevant device measurements
   * @returns one ModelSensor
   */
   async getRelevantDeviceMeasurements(urn: string) {
    const payload = await getAuthorizationHeader();
    const payloadParams = {
      params: {
        deviceURN: urn,
      }
    }
    Object.assign(payload, payloadParams)
    const result = await axios.get(environment.VUE_APP_BASE_URL_V2 + 'relevant-device-measurements', payload);

    // --- Fix timestream measure timezone
    result.data.map((measure: any) => {
      const currentTimezoneOffset = moment(measure.time).tz("Europe/Paris").utcOffset();
      measure.time = moment(measure.time).add(currentTimezoneOffset, "minutes").format("YYYY-MM-DD HH:mm:ss")

      return measure
    })

    return result
  },

  /**
   * Get all sensor positions by user collectivity id
   * @returns List of PositionModel
   */
  async getMapDataByCollectivity(authorityId: number) {
    const requestHeaders = await getAuthorizationHeader();
    return axios.get(environment.VUE_APP_BASE_URL + authorityId  + '/sensors/position', requestHeaders);
  },

  /**
   * Get map sensor positions bu tag name
   * @param tag names
   * @returns List of PositionModel
   */
  async getMapDataByTags(authorityId: number, tags: string[]) {
    const payload = {
      tags: tags
    }
    const requestHeaders = await getAuthorizationHeader();
    return axios.post(environment.VUE_APP_BASE_URL + authorityId  + '/sensors/position', payload, requestHeaders);
  },

  /**
   * Get list of last measures by tag names
   * @param tag names
   * @returns List of MeasureModel
   */
  async getLastMeasures(authorityId: number, tags: string[], keys: string[]) {
    const payload = {
      tags: tags,
      keys: keys
    }
    const requestHeaders = await getAuthorizationHeader();
    // return axios.post(environment.VUE_APP_BASE_URL + authorityId + '/lastMeasures', payload); // Waiting for
    return axios.post(environment.VUE_APP_BASE_URL + authorityId  + '/measures/last', payload, requestHeaders);
  },

  /**
   * Get list of measures in error by collectivity id
   * @param id of the collectivity
   * @returns List of MeasureModel
   */
  async getMeasureAlerts(authorityId: number) {
    const requestHeaders = await getAuthorizationHeader();
    return axios.get(environment.VUE_APP_BASE_URL + authorityId + '/measures/alerts', requestHeaders);
  },

  /**
   * Get list of sensors in error by collectivity id
   * @param id of the collectivity
   * @returns List of ModelSensorAlert
   */
  async getSensorAlerts(authorityId: number) {
    const requestHeaders = await getAuthorizationHeader();
    return axios.get(environment.VUE_APP_BASE_URL + authorityId + '/sensors/alerts', requestHeaders);

  },

  /**
   * Get user selections
   * @returns selections saved for the user
   */
  async getSelections() {
    const requestHeaders = await getAuthorizationHeader();
    const userId = (await Auth.currentUserInfo()).attributes.sub;

    return axios.get(environment.VUE_APP_BASE_URL + userId + '/selections/favorites', requestHeaders);
  },

  /**
   * Get last 5 user selections
   * @returns selections used by the user
   */
  async getLastSelections() {
    const requestHeaders = await getAuthorizationHeader();
    const userId = (await Auth.currentUserInfo()).attributes.sub;
    return axios.get(environment.VUE_APP_BASE_URL + userId + '/selections/history', requestHeaders);
  },

  /**
   * delete given user selection
   */
  async deleteSelection(selection: Selection) {
    const requestHeaders = await getAuthorizationHeader();
    return axios.delete(environment.VUE_APP_BASE_URL + 'selections/' + selection.id, requestHeaders);
  },

  /**
   * Get list of all tags of the collectivity
   * @param collectivityId
   * @returns List of ModelTag
   */
  async getAllTags(authorityId: number) {
    const requestHeaders = await getAuthorizationHeader();
    return axios.get(environment.VUE_APP_BASE_URL + authorityId + '/tags', requestHeaders);

  },

  /**
   * Get list of all sensors of the collectivity
   * @param collectivityId
   * @returns List of ModelSensor
   */
  async getAllSensors(authorityId: number) {
    const requestHeaders = await getAuthorizationHeader();
    return axios.get(environment.VUE_APP_BASE_URL + authorityId + '/sensors', requestHeaders);
  },

  /**
   * Get list of all devices of the collectivity
   * @param collectivityId
   * @returns List of ModelSensor
   */
  async getAllDevices(authorityId: number) {
    const requestHeaders = await getAuthorizationHeader();
    return axios.get(environment.VUE_APP_BASE_URL + authorityId + '/device', requestHeaders);
  },

  /**
   * Get tree of all tags of the collectivity with their children
   * @param collectivityId
   * @returns List of ModelTag
   */
  async getTagsTree(authorityId: number) {
    const requestHeaders = await getAuthorizationHeader();
    return axios.get(environment.VUE_APP_BASE_URL + authorityId + '/tags/tree', requestHeaders);
  },

  /**
   * Get user information
   * @returns ModelUserInfo
   */
  async getUserInfo() {
    const requestHeaders = await getAuthorizationHeader();
    const userId = (await Auth.currentUserInfo()).attributes.sub;
    return axios.get(environment.VUE_APP_BASE_URL + userId + '/userInfo', requestHeaders);
  },

  /**
   * Get children of a given tag
   * @param collectivityId id of the ccollectivity
   * @param tagId of the parent tag
   * @returns List of ModelTag
   */
  async getChildrenTags(authorityId: number, tagId: string) {
    const requestHeaders = await getAuthorizationHeader();
    return axios.get(environment.VUE_APP_BASE_URL + authorityId + '/tags/' + tagId + '/children', requestHeaders);
  },

  /**
   * Save the user Selection
   * @param selection to save
   * @returns
   */
  // Nous aurons surement un userId à fournir
  async saveSelection(selection: Selection) {
    const userId = (await Auth.currentUserInfo()).attributes.sub;
    selection.userId = userId;
    const payload = {
      selection: Selection.toModel(selection)
    };
    const requestHeaders = await getAuthorizationHeader();
    return axios.post(environment.VUE_APP_BASE_URL + 'selections/save', payload, requestHeaders);
  },

  async getMeasureKeys(collectivityId: string) {
    const requestHeaders = await getAuthorizationHeader();
    return axios.get(environment.VUE_APP_BASE_URL + 'measures/keys', requestHeaders);
  },

  async saveTags(authorityId: number, tags: Tag[]) {
    const payload = {
      tags: tags
    };
    const requestHeaders = await getAuthorizationHeader();

    return axios.post(environment.VUE_APP_BASE_URL + authorityId + '/tags/save', payload, requestHeaders);
  },

  async saveMeasureKeys(measureKeys: MeasureKey[]) {
    const payload = {
      measureKeys: measureKeys
    };
    const requestHeaders = await getAuthorizationHeader();

    return axios.post(environment.VUE_APP_BASE_URL + 'measures/saveKeys', payload, requestHeaders);
  },

  async saveSensors(authorityId: number, sensors: Sensor[]) {
    const payload = {
      sensors: sensors
    };
    const requestHeaders = await getAuthorizationHeader();

    return axios.post(environment.VUE_APP_BASE_URL + authorityId + '/sensors/save', payload, requestHeaders);
  },

  /**
     * Update device alias
     * @param params
     */
   async updateAlias(params) {
    return axios.put(environment.VUE_APP_BASE_URL_V2 + 'device', { data: params });
  },
};
