<template>
  <div class="select body-small">
    <label :for="`select-chart-${uuid}`">{{ $t("stats.select") }}</label>
    <input type="checkbox" :id="`select-chart-${uuid}`" v-model="selected">
  </div>
</template>

<script>
export default {
  name: "SelectComponent",
  props: {
    uuid: String
  },
  computed: {
    selected: {
      // getter
      get: function () {
          return this.$store.getters.isChartSelected(this.uuid);
      },
      // setter
      set: function () {
          this.$store.dispatch('toggleChartSelection', this.uuid);
      }
    }
  },
}
</script>


