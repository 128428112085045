export const environment = {
  VUE_APP_BASE_URL: 'https://auth.horizon.serfimtic.net/',
  VUE_APP_BASE_URL_V2: 'https://fno9idc2v7.execute-api.eu-west-3.amazonaws.com/prod/',
  VUE_APP_BASE_URL_API_FRONT: 'https://0ov6xy4yi7.execute-api.eu-west-3.amazonaws.com/prod/',
  amplify: {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-1',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-1_rlq08dl16',
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '5nrlpp77n9okcv7ui3e7pc2a42'
    },
    API: {
      endpoints: [
        {
          name: 'api',
          region: 'eu-west-1',
          endpoint: 'https://auth.horizon.serfimtic.net/'
        }
      ]
    }
  },
  oauth: {
    domain: 'https://login.horizon.serfimtic.net/',
    scope: ['openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `https://horizon.serfimtic.net/`,
    redirectSignOut: 'https://horizon.serfimtic.net/',
    responseType: 'code'
  }
};
