import ChartInfo from "@/domain/chart/ChartInfo";
import Duration from "@/domain/state/Duration";
import State from "@/domain/state/State";
import DateRange from "@/domain/state/DateRange";
import {Moment} from "moment";
import Tag from "@/domain/state/Tag";
import UserInfo from "@/domain/state/UserInfo";
import MeasureKey from "@/domain/state/MeasureKey";
import Sensor from "@/domain/state/Sensor";
import Selection from "@/domain/state/Selection";

const getters = {
    // --- Get tags
    tags(state: State): Tag[] {
        return state.tags;
    },
    // --- Get selected tags
    selectedTags(state: State): Tag[] {
        return state.selectedTags;
    },
    // --- Get sensors
    sensors(state: State): Sensor[] {
        return state.sensors
    },
    // --- Get sensors
    selections(state: State): Selection[] {
        return state.selections
    },
    // --- Get loader
    loader(state: State): boolean {
        return state.loader
    },


    // -------------------------------------
    duration(state: State): Duration {
        return state.selectedDuration;
    },

    range(state: State): DateRange {
        return new DateRange(state.startDate, state.endDate);
    },

    startDate(state: State): Moment {
        return state.startDate;
    },

    userInfo(state: State): UserInfo {
        return state.userInfo;
    },

    endDate(state: State): Moment {
        return state.endDate;
    },

    charts: (state) => {
        return state.charts;
    },

    chartById: (state) => (chartUuid) => {
        return state.charts.find(it => it.uuid == chartUuid);
    },

    isChartSelected: (state) => (chartUuid) => {
        return state.selectedChartUuid.includes(chartUuid);
    },

    getselectedChartUuid: (state) => {
        return state.selectedChartUuid;
    },

    statsByChartId: (state) => (chartUuid) => {
        const chartInfo: ChartInfo = state.charts.find(it => it.uuid == chartUuid);

        if (chartInfo && chartInfo.chartData) {
            return chartInfo.chartData.stats;
        }
        return {};
    },


    availableMeasureKeys(state: State): MeasureKey[] {
        return state.availableMeasureKeys;
    },

    selectedMeasureKeys(state: State): MeasureKey[] {
        return state.selectedMeasureKeys;
    },
    
    selectedChartUuids(state: State) {
        return state.selectedChartUuid;
    },

    expandedMenu(state: State) {
        return state.expandedMenu;
    },

    authorityId(state: State) {
        return state.userInfo.authorityId;
    }
}

export default getters;
