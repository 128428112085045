<template>
  <div class="sensor" v-if="sensor">
    <div class="sensor-wrapper">
      <div class="icon-wrapper">
        <span class='icon-sensor'><font-awesome-icon :icon="['fas', 'tower-broadcast']" fixed-width /></span>
      </div>
      <div class="sensor-info">
        <div class="sensor-info-title">
          {{
            $t("home.alerts.sensorId", {
              id: sensor.alias ? sensor.alias : sensor.name,
            })
          }}
        </div>
        <div class="sensor-info-tags">
          <span v-for="locationTag of locationTags" :key='locationTag'>
            {{ locationTag }}
          </span>
        </div>
      </div>
    </div>
    <div class="sensor-error">
      <div class="error-label">
        {{ $t("home.alerts.inactive") }}<span class="caution-icon"><font-awesome-icon :icon="['fas', 'circle-exclamation']" fixed-width /></span>
      </div>
      <div class="error-message">
        <span>{{ $t("home.alerts.lastMeasure") }}</span>
        <span>{{ lastMeasureDate }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import TagType from "@/domain/state/TagType";
import moment from 'moment';
import {Locale, DateFormat} from '@/service/Constants';

export default {
  name: "SensorComponent",
  props: ['sensor'],
  computed: {
    locationTags() {
      if (this.sensor.tags && this.sensor.tags.length) {
        if (this.sensor.tags.filter((it) => it.type == TagType.LOCATION)) {
          return this.sensor.tags
            .filter((it) => it.type == TagType.LOCATION)
            .map((it) => it.name);
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    lastMeasureDate() {
      return moment(this.sensor.lastMeasureDate).locale(Locale).format(DateFormat.LITTERAL)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/components/alertComponent.scss";
</style>