<template>
  <div class="badge" :class="getClass">
    <div class="name" @click="clickAction"><template v-if="tagParentName"><span class="parent">{{ tagParentName }}</span> | </template>{{ tag.name }}</div>
    <p v-if="deletable" @click="removeAction" class="remove-button"><font-awesome-icon :icon="['fas', 'times']" size="sm" fixed-width /></p>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'Badge',
  props: ["tag", "deletable", "isSelecting", "tagParentName", "deletingTags", "isChart"],
  computed: {
    getClass() {
      return this.isChart ? 'viewing' : this.tag.type.toLowerCase()
    }
  },
  methods: {
    removeAction() {
      if(this.deletingTags) {
        this.$emit('deleteTag', this.tag);
      } else {
        this.$emit('removeTag', this.tag);
      }
    },
    clickAction() {
      if(this.isSelecting) {
        this.$emit('addTag', this.tag);
      }
    }
  }
})

</script>

<style lang="scss" scoped>
@import "../../styles/components/badgeComponent.scss";
</style>
