import ChartInfo from '@/domain/chart/ChartInfo';
import DateRange from '@/domain/state/DateRange';
import MeasureKey from '@/domain/state/MeasureKey';
import SearchInfo from '@/domain/state/SearchInfo';
import Tag from '@/domain/state/Tag';
import UserInfo from '@/domain/state/UserInfo';
import Sensor from "@/domain/state/Sensor";
import Selection from "@/domain/state/Selection";
import { RangeAction } from '@/service/Constants';

const actions = {
  // --- Set sensors
  setSensors(state: any, sensors: Sensor[]) {
    state.commit('setSensors', sensors);
  },
  // --- Set tags
  setTags(state: any, tags: Tag[]){
    state.commit('setTags', tags);
  },
  // --- Set selections
  setSelections(state: any, selections: Selection[]){
    state.commit('setSelections', selections);
  },
  // --- Clear tags
  clearTags(state: any) {
    state.commit('clearTags');
  },
  // --- Set charts
  setCharts(state: any, charts: ChartInfo[]) {
    state.commit('setCharts', charts);
  },
  // --- Set charts
  setLoader(state: any, value: boolean) {
    state.commit('setLoader', value);
  },

  // ----------------------------------------------------

  selectDuration (state: any, value: string) {
    state.commit('selectDuration', value);
  },
  selectRange (state: any, range: DateRange) {
    state.commit('selectRange', range);
  },
  refreshCharts(state: any) {
    state.commit('updateCharts');
  },
  changeRange(state: any, action: RangeAction) {
    state.commit('changeRange', action);
  },
  toggleChartSelection(state: any, uuid: string) {
    state.commit('toggleChartSelection', uuid);
  },
  removeSelectedCharts(state: any) {
    state.commit('removeSelectedCharts');
  },
  mergeSelectedCharts(state: any) {
    state.commit('mergeSelectedCharts');
    state.commit('removeSelectedCharts');
  },
  toggleExpandedMenu(state: any, expanded: boolean) {
    state.commit('toggleExpandedMenu', expanded);
  },
  searchForResult(state: any, searchInfo: SearchInfo) {
    state.commit('searchForResult', searchInfo.tags);
    state.commit('emptyChartList');
  },
  emptyChartList(state: any) {
    state.commit('emptyChartList');
  },
  setUserInfo(state: any, userInfo: UserInfo) {
    state.commit('setUserInfo', userInfo);
  },
  addSelection(state: any, selection: Selection) {
    state.commit('addSelection', selection);
  },
  populateMeasureKeys(state: any, keys: MeasureKey[]) {
    state.commit('populateMeasureKeys', keys);
  },
  updateSelectedMeasureKeys(state: any, keys: MeasureKey[]) {
    state.commit('updateSelectedMeasureKeys', keys);
  },
  clearZoneTags(state: any) {
    state.commit('clearZoneTags');
  }
}

export default actions;
