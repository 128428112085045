import axios from "axios";
import { environment } from "../../.env.development";

export default {
    /**
     * Return list of devices
     */
    getAllDevices() {
        return axios.get(environment.VUE_APP_BASE_URL_API_FRONT + 'devices');
    },
    /**
     * Add/update device
     */
    postDevice(params) {
        return axios.post(environment.VUE_APP_BASE_URL_API_FRONT + 'devices', params);
    },
    /**
     *
     * Return list of tags
     */
    getAllTags() {
        return axios.get(environment.VUE_APP_BASE_URL_API_FRONT + 'tags');
    },
    /**
     * Add/Update tag
     * @param params
     */
    postTag(params) {
        return axios.post(environment.VUE_APP_BASE_URL_API_FRONT + 'tags', params);
    },
    /**
     * Delete tag
     * @param params
     */
    deleteTag(params) {
        return axios.delete(environment.VUE_APP_BASE_URL_API_FRONT + 'tags', { data: params });
    },
    /**
     * Get all selections
     */
    getAllSelections() {
        return axios.get(environment.VUE_APP_BASE_URL_API_FRONT + 'selections');
    },
    /**
     * Add/update selection
     */
    postSelection(params) {
        return axios.post(environment.VUE_APP_BASE_URL_API_FRONT + 'selections', params);
    },
    /**
     * Delete selection
     * @param params
     */
    deleteSelection(params) {
        return axios.delete(environment.VUE_APP_BASE_URL_API_FRONT + 'selections', { data: params });
    },
}
