<template>
    <div class="measure-icon">
      <div class="measure-icon" v-if="!hasError">
        <span v-if="measureKey == 'hygro'"><font-awesome-icon :icon="['fas', 'droplet']" fixed-width /></span>
        <span v-if="measureKey == 'temp'"><font-awesome-icon :icon="['fas', 'faTemperatureHalf']" fixed-width /></span>
        <span v-if="measureKey == 'cov'"><font-awesome-icon :icon="['fas', 'cloud']" fixed-width /></span>
        <span v-if="measureKey == 'co2'"><font-awesome-icon :icon="['fas', 'cloud']" fixed-width /></span>
      </div>
      <div class="measure-icon" v-if="hasError">
        <span><font-awesome-icon :icon="['fas', 'triangle-exclamation']" fixed-width /></span>
      </div>
    </div>
</template>

<script>

export default {
    name: "MeasureIcon",
    props: ['measureKey', 'hasError']
}
</script>