export default {
    date: {
        day: 'Jour',
        week: 'Semaine',
        month: 'Mois',
        year: '3 derniers mois',
        datepicker: 'Dates:'
    },
    stats: {
        min: 'Minimum',
        max: 'Maximum',
        avg: 'Moyenne',
        select: 'Sélectionner'
    },
    tags: {
        selection: 'Ma sélection',
        size: '({size} tags)',
        save: 'Sauvegarder',
        edit: 'Modifier'
    },
    measures: {
        last: 'Dernières mesures',
        location: {
            allLabel: 'Tous',
            name: 'Emplacement:'
        },
        measure: {
            allLabel: 'Toutes',
            name: 'Mesures:'
        },
        sensor: 'Capteur'
    },
    backToHome: 'Retour à l\'accueil',
    footer: {
        label: 'graphiques sélectionés',
        cancel: 'Annuler',
        merge: 'Fusionner les graphiques'
    },
    home: {
        welcomeMessage: 'Bienvenue !',
        alerts: {
            title: 'Mes alertes',
            measureAlerts: 'Alertes mesures ({size})',
            sensorAlerts: 'Alertes capteurs ({size})',
            sensorId: 'Capteur #{id}',
            inactive: 'Inactif',
            lastMeasure: 'Dernière mesure',
            noMeasureAlert: 'Pas d\'alertes mesures',
            noSensorAlert: 'Pas d\'alertes capteurs'
        },
        selection: {
            title: 'Mes dernières visualisations',
            new: 'Nouvelle visualisation',
            history: 'Visualisation du {date}'
        },
        coverZone: 'Ma zone de couverture'
    },
    menu: {
        title: 'SERFIM TIC',
        home: 'Accueil',
        map: 'Carte',
        favorite: 'Mes sélections',
        sensors: 'Gestion des capteurs',
        tags: 'Gestion des tags',
        selections: 'Voir mes sélections',
        signout: "Se déconnecter"
    },
    search: {
        header: 'Effectuer une recherche',
        new: 'Nouvelle sélection',
        measure: 'Mesures',
        location: 'Emplacement',
        loading: 'Chargement des résultats',
        all: 'Toutes',
        viewResults: 'Voir les résultats'
    },
    selection: {
        selections: 'Mes sélections',
        title: 'Sauvegarder la sélection',
        save: 'Sauvegarder',
        cancel: 'Annuler'
    },
    tagAdmin: {
        header: 'Gestion des tags',
        measureHeader: 'Gestion des tags de mesure',
        save: 'Sauvegarder',
        title: 'Ajouter un tag',
        add: 'Ajouter',
        cancel: 'Annuler',
        tagPlaceholder: 'Saisissez un nom pour le tag',
        addMeasureKey: 'Ajouter un nouveau type de mesure',
        graphType: {
            bar: 'Histogramme',
            line: 'Courbe'
        }
    },
    sensorAdmin: {
        header: 'Gestion des capteurs',
        save: 'Sauvegarder',
        title: 'Ajouter un tag',
        sensorTitle: 'Ajouter un capteur',
        add: 'Ajouter',
        cancel: 'Annuler',
        sensorPlaceholder: 'Saisissez un nom pour le capteur',
        latitude: 'Saisissez une latitude',
        longitude: 'Saisissez une longitude',
        noTag: 'Aucun tag défini',
        tagModal: {
            title: 'Gestion des tags du capteur',
            addTag: 'Ajouter un tag',
            createTag: 'Créer un tag',
            deleteTag: 'Voulez-vous supprimer ce tag ainsi que ses enfants ?',
            deleteTagInfo: 'Information: cette suppression entrainera la suppression de ce tag sur les autres capteurs',
            selectedTag: 'Tag(s) sélectionné(s) :',
            noTagSelected: 'Aucun tag sélectionné',
            availableTag: 'Tag(s) disponible(s) :',
            noTagAvailable: 'Aucun tag disponible',
            no: 'NON',
            yes: 'OUI',
            cancel: 'ANNULER',
            submit: 'VALIDER'
        }
    },
    selectionAdmin: {
        header: 'Gestion des sélections',
        tagModal: {
            title: 'Gestion des sélections',
            content: 'Voulez-vous supprimer cette sélection ?'
        }
    },
    chart: {
        modal: {
            title: "Voulez-vous sauvegarder la sélection ?"
        },
        noChart: 'Aucun graphique disponible',
        legend: {
            healthy: "Saine",
            good: "Bonne",
            acceptable: "Acceptable",
            fair: "Acceptable",
            average: "Moyenne",
            moderate: "Dégradé",
            low: "Basse",
            poor: "Mauvaise",
            veryLow: "Très basse",
            veryPoor: "Très mauvaise",
            extremelyPoor: "Extrêmement mauvaise",
            dangerous: "Dangereuse",
        }
    }
}
