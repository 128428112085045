
export default class AnnotationLabel {
    backgroundColor: string;
    content: number;
    enabled: boolean;
    position: string;

    static from(value: number, color: string): AnnotationLabel {
        const result = new AnnotationLabel();

        result.backgroundColor = color ?? 'grey';
        result.content = value;
        result.enabled = true;
        result.position = "right";

        return result;
    }

}