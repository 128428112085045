<template>
  <div class="selection-wrapper">
    <SelectionComponent />
    <SelectionComponent v-for="(selection, index) in selectionsCookie" :key='index' :selection="selection"/>
  </div>

</template>

<script>
import Vue from 'vue';
import SelectionComponent from '@/components/selection/SelectionComponent.vue';
import ClientService from '@/service/RestClientService';
import Selection from '@/domain/selection/Selection';

export default Vue.extend({
  name: 'SelectionWrapperComponent',
  components: {
    SelectionComponent
  },
  data() {
    return {
      selectionsCookie : []
    }
  },
  mounted() {
    // --- Récupération des sélections en cookies
    if (this.$cookies.get("searchSelections")) {
      this.selectionsCookie = this.$cookies.get("searchSelections").reverse()
    }
  }
})

</script>


