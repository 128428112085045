import { AxisIds, RangeAction, Colours } from "@/service/Constants";
import ChartDataset from "./ChartDataset";
import Annotation from "./Annotation";
import { ChartColours, ChartColourBounds } from '@/service/Constants';

export default class ChartOptions {
    maintainAspectRatio: boolean;
    scales: any;
    annotation: any;
    legend: any;
    tooltips: any;

    constructor() {
        this.maintainAspectRatio = false;
    }

    setDisplayXAxisLabels(value: boolean) {
        this.scales.xAxes[0].display = value;
    }

    static addYAxis(chartOptions: ChartOptions, index: number, graphScales) {
        const pos = index % 2 == 0 ? 'left' : 'right';
        chartOptions.scales.yAxes.push({
            id: AxisIds[index],
            type: 'linear',
            position: pos,
            gridLines: {
                color: "rgba(0, 0, 0, 0)",
            },
            ticks: {
                fontColor: Colours[index],
                beginAtZero: false,
                min: graphScales.min,
                max: graphScales.max,
            }
          });
    }

    static addAnnotation(chartOptions: ChartOptions, value: number, datasets?: ChartDataset[]) {
        let color = ChartColours.defaultGrey;

        if (datasets) {
            datasets.forEach(dataset => {
                if(dataset.coloredGraphTypes.includes(dataset.dataType)) {
                    // --- Set color on threshold depending on value
                    Object.keys(ChartColourBounds[dataset.dataType]).map(range => {

                        // --- Check range of dataType
                        if (value >= ChartColourBounds[dataset.dataType][range].min
                          && value < ChartColourBounds[dataset.dataType][range].max) {
                            color = ChartColours[range]
                        }
                    })
                }
            })
        }


        chartOptions.annotation = {
            annotations: [Annotation.from(value, color)]
        }
    }

    static setGraphScales(chartOptions: ChartOptions, graphScales: { min: any; max: any; }) {
        chartOptions.scales.yAxes.forEach(yAxe => {
            yAxe.ticks.beginAtZero = false;
            yAxe.ticks.min = graphScales.min;
            yAxe.ticks.max = graphScales.max;
        });
    }

    static barChartOptions(chartOptions: ChartOptions) {
        chartOptions.scales.xAxes.forEach(xAxe => {
            xAxe.barPercentage = 1.0;
            xAxe.categoryPercentage = 1.0;
        });
    }

    // --- TODO
    static setCustomLabel(chartOptions: ChartOptions, xCustomLabels: [], yCustomLabels: string[]) {
        if(xCustomLabels.length) {
            chartOptions.scales.xAxes.ticks = {
                callback: value => {
                    return xCustomLabels[value]
                }
            }
        }
        if(yCustomLabels.length) {
            chartOptions.scales.yAxes.ticks = {
                display: true,
                callback: function(value, index, values) {
                    console.log("VALUE", value)
                }
            }
        }
    }

    static copy(chartOptions: ChartOptions): ChartOptions {
        const result = new ChartOptions();
        result.maintainAspectRatio = false;
        result.scales = chartOptions.scales;
        return result;
    }

    static from(): ChartOptions {

        const result: ChartOptions = new ChartOptions();

        result.annotation = undefined;

        result.legend = {
            display: false
        }

        result.scales = {
            xAxes: [{
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 7,
                    maxRotation: 0,
                    minRotation: 0,
                    callback: (val: string) => {
                        return val.split("\n")[0].split(" : ")[1];
                    },
                },
                gridLines: {
                    color: "rgba(0, 0, 0, 0.05)",
                },
            }],
            yAxes: [{
                id: 'y',
                gridLines: {
                    color: "rgba(0, 0, 0, 0.05)",
                },
                ticks: {
                    beginAtZero: true,
                },
            }],
        }

        result.tooltips = {
            callbacks: {
                label: () => {
                    return;
                }
            }
        }

        return result;
    }
}
