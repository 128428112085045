import TagType from "./TagType";

export default class Tag {
    id: string;
    name: string;
    type: TagType;
    parentId: string;
    childrenId: string[] = [];
    typeToSelect: string;
    editable: boolean;
    sdm: [];

    public static from (model: any): Tag {
        const result = new Tag();
        result.id = model.id;
        result.name = model.name;

        result.parentId = model.parentId;

        result.type = model.type as TagType;

        result.editable = model.editable;

        if (model.childrenId) {
            model.childrenId.forEach(childId => result.childrenId.push(childId));
        }

        if(model.typeToSelect) {
            result.typeToSelect = model.typeToSelect
        }

        result.sdm = model.sdm;

        return result;

    }
}
