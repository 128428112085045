import MeasureKey from "../state/MeasureKey";
import Tag from "../state/Tag";
import SelectionType from "./SelectionType";
import { ModelMeasureKey, ModelSelection, ModelSelectionType, ModelTag } from "@/domain/model/Model";

export default class Selection {
    id: string;
    name: string;
    tags: Tag[] = [];
    measureKeys: MeasureKey[] = [];
    type: SelectionType;
    creationDate: string;
    userId: string;

    static from(model: any) {
        const result = new Selection();

        if (model.id) {
            result.id = model.id;
        }
        if (model.name) {
            result.name = model.name;
        }
        if (model.tags) {
            model.tags.forEach(modelTag => {
                result.tags.push(Tag.from(modelTag));
            });
        }
        if (model.keys) {
            model.keys.forEach(modelMeasureKey => {
                result.measureKeys.push(MeasureKey.from(modelMeasureKey));
            });
        }
        if (model.type) {
            result.type = model.type as SelectionType;
        }
        if (model.creationDate) {
          result.creationDate = model.creationDate;
        }
        if (model.userid) {
          result.userId = model.userid;
        }

        return result;
    }

    static toModel(selection: any): ModelSelection {
      return ModelSelection.from(selection.id, selection.name, selection.tags as ModelTag[], selection.measureKeys as ModelMeasureKey[], selection.type, selection.creationDate, selection.userId);
    }
}
