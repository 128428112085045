import ClientService from '@/service/RestClientService';
import {measureTypes} from "@/service/ChartsParams";
import {
    Asset,
    AirQualityObserved,
    DeviceMeasurement,
    ParkingSpot,
    SmartMeteringObservation,
    NoiseLevelObserved
} from "@/service/SmartDataModels";
import ChartInfo from '@/domain/chart/ChartInfo';
import ChartData from '@/domain/chart/ChartData';
import Sensor from "@/domain/state/Sensor";
import {v4 as uuid} from 'uuid';
import { Moment } from 'moment';
import store from '../store'

export function isJsonString(str: string) {
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}

export function transformStructToJson(value: string) {
    if(!value.includes('{') && !value.includes('}')) return value

    let result = value
    if(!isJsonString(result)) {
        result = value
            .replace(/{/g, '{"')
            .replace(/=/g, '":"')
            .replace(/, /g, '", "')
            .replace(/}/g, '"}')
    }
    result = JSON.parse(result)

    return result
}

/**
* Get devices measurements according to the tags and dates
*/
export async function getDevicesMeasurements(devices: any, charts: ChartInfo[], startDate: Moment, endDate: Moment, params: any, mergedDatasets: any) {
    await ClientService.getAllDeviceMeasurements(startDate, endDate, params).then(async response => {

        const result = await processMeasures(devices, response)

        let emptyChannelURNs = params.devicesURNs

        // --- Ajout des stats
        await Promise.all(Object.keys(result).map((resultKey) => {
            if (result[resultKey].makeStat) {
                const stats = {
                    min: Math.min(...result[resultKey].objectToAdd.value),
                    max: Math.max(...result[resultKey].objectToAdd.value),
                    avg: Number((result[resultKey].objectToAdd.value.reduce((accumulatedValue, currentValue) => parseFloat(accumulatedValue) + parseFloat(currentValue)) / result[resultKey].objectToAdd.value.length).toFixed())
                }

                if (!result[resultKey].objectToAdd.stats) !result[resultKey].objectToAdd.stats

                result[resultKey].objectToAdd.stats = stats
            }

            if (result[resultKey].objectToAdd.value && result[resultKey].objectToAdd.value.length) {

                // --- Récupération de l'alias du capteur
                const sensor = devices.find((d: { urn: string[]; }) => d.urn.some((u: string) => u.includes(result[resultKey].label.split(" - ")[0])))

                if (sensor) {
                    const title = sensor.alias ? sensor.alias : sensor.name

                    // --- Mise en place des données sur le Model ChartInfo
                    const chartInfo = ChartInfo.from(uuid(), ChartData.from(result[resultKey].objectToAdd, result[resultKey].graphType, result[resultKey].label, title), result[resultKey].threshold, (title ? title : ''), result[resultKey].label);

                    // --- Ajout des éléments au composant
                    charts.push(chartInfo)
                }

                // --- Récupération des graphiques sans aucune mesure
                emptyChannelURNs = emptyChannelURNs.filter(u => !u.includes(result[resultKey].label.split("-")[0]))
            }

        }))

        // --- Ajout des chartInfo sans aucune mesure
        if (emptyChannelURNs.length) {
            emptyChannelURNs.map((channelURN: string) => {
                // --- Récupération de l'alias du capteur
                const sensor = devices.find((d: { urn: string[]; }) => d.urn.some((u: string) => u.includes(channelURN.split(":")[channelURN.split(":").length-1])))

                if (sensor) {
                    const title = sensor.alias ? sensor.alias : sensor.name

                    // --- Mise en place des données sur le Model ChartInfo
                    const chartInfo = ChartInfo.from(uuid(), undefined, undefined, (title ? title : ''), channelURN.split(":")[channelURN.split(":").length-1]);

                    // --- Ajout des éléments au composant
                    charts.push(chartInfo)
                }
            })
        }

        if (mergedDatasets.length) {
            mergedDatasets.map((mergedDatasetLabels: string | string[]) => {
                charts.map(chart => {
                    chart.chartData.datasets.map(dataset => {
                        if (mergedDatasetLabels.includes(dataset.label)) {
                            store.dispatch('toggleChartSelection', chart.uuid)
                        }
                    })
                })
            })

            if (store.getters.getselectedChartUuid.length > 1) {
                store.dispatch('mergeSelectedCharts')
            }
        }

    }).catch((error) => {
        throw new Error(error);
    })
}

/**
 * Get devices relevant measurements (last measure)
 */
export async function getRelevantDevicesMeasurements(sensors: any) {
    await Promise.all(sensors.map(async (sensor: any) => {
        sensor.measurements = [];
        await Promise.all(sensor.urn.map(async (urn: string) => {
            await ClientService.getRelevantDeviceMeasurements(urn).then(async response => {
                const result = await processMeasures(sensors, response)

                Object.keys(result).forEach((resultKey) => {
                    if (result[resultKey].objectToAdd.value && result[resultKey].objectToAdd.value.length) {
                        sensor.measurements.push(
                            {
                                urn : urn, alias: result[resultKey].label.split(" - ")[0],
                                value: result[resultKey].objectToAdd.value[0],
                                type: result[resultKey].objectToAdd.type,
                                unit: result[resultKey].objectToAdd.unit,
                                time: result[resultKey].objectToAdd.timestamp[0]
                            }
                        )
                    }
                    // --- Consuption sensors
                    else if (result[resultKey].objectToAdd.index && result[resultKey].objectToAdd.index.length) {
                        sensor.measurements.push(
                            {
                                urn : urn, alias: result[resultKey].label.split(" - ")[0],
                                value: result[resultKey].objectToAdd.index[0],
                                type: result[resultKey].objectToAdd.type,
                                unit: result[resultKey].objectToAdd.unit,
                                time: result[resultKey].objectToAdd.timestamp[0]
                            }
                        )
                    }
                })
            })
        }))
    }))
}


async function processMeasures(sensors: any, response: any) {
    const result = {}
    const savedMeasureForDelta = {};
    await Promise.all(response.data.map((measure: any) => {
        // --- Récupération du nom du device
        const deviceId = measure.id.split(':').pop()

        // --- Construction de l'objet en fonction du type de Smart Data Model
        const deviceMeasureTypes = measureTypes[measure.type]

        // --- Création de l'objet si inexistant
        if (!result[deviceId]) result[deviceId] = {
            label: deviceId,
            graphType: deviceMeasureTypes.graphType,
            threshold: deviceMeasureTypes.threshold,
            objectToAdd: {}
        }

        switch (measure.type) {
            case 'Asset': {
                // --- Suppression de l'entrée avec l'ID simple
                delete result[deviceId];

                // --- Si le type de mesure n'existe pas dans la mesure
                if (!Object.keys(deviceMeasureTypes).includes(measure.measure_name)) return;

                const deviceIdKey = `${deviceId}-${measure.measure_name}`

                if (!result[deviceIdKey]) result[deviceIdKey] = {
                    label: `${deviceId} - ${deviceMeasureTypes[measure.measure_name].type}`,
                    graphType: deviceMeasureTypes[measure.measure_name].graphType,
                    type: deviceMeasureTypes[measure.measure_name].type,
                    objectToAdd: {}
                }
                result[deviceIdKey] = Asset(result[deviceIdKey], measure, deviceMeasureTypes)
                break;
            }
            case 'DeviceMeasurement': {
                result[deviceId] = DeviceMeasurement(result[deviceId], measure, deviceMeasureTypes.graphType)
                break
            }
            case 'ParkingSpot': {
                // --- Ajout des informations
                result[deviceId] = ParkingSpot(result[deviceId], measure, deviceMeasureTypes.graphType)
                break
            }
            case 'AirQualityObserved': {
                // --- Suppression de l'entrée avec l'ID simple
                delete result[deviceId]

                // --- Si le type de mesure n'existe pas dans la mesure
                if (!Object.keys(deviceMeasureTypes).includes(measure.measure_name)) return;

                const deviceIdKey = `${deviceId}-${measure.measure_name}`

                if (!result[deviceIdKey]) result[deviceIdKey] = {
                    label: `${deviceId} - ${deviceMeasureTypes[measure.measure_name].type}`,
                    graphType: deviceMeasureTypes[measure.measure_name].graphType,
                    type: deviceMeasureTypes[measure.measure_name].type,
                    unit: deviceMeasureTypes[measure.measure_name].unit,
                    threshold: getThreshold(sensors, deviceId) ? getThreshold(sensors, deviceId)[deviceMeasureTypes[measure.measure_name].type] : "",
                    makeStat: deviceMeasureTypes[measure.measure_name].stats,
                    objectToAdd: {}
                }
                result[deviceIdKey] = AirQualityObserved(result[deviceIdKey], measure, deviceMeasureTypes)
                break;
            }
            case 'NoiseLevelObserved': {
                // --- Suppression de l'entrée avec l'ID simple
                delete result[deviceId]

                // --- Si le type de mesure n'existe pas dans la mesure
                if (!Object.keys(deviceMeasureTypes).includes(measure.measure_name)) return;

                const deviceIdKey = `${deviceId}-${measure.measure_name}`

                if (!result[deviceIdKey]) result[deviceIdKey] = {
                    label: `${deviceId} - ${deviceMeasureTypes[measure.measure_name].type}`,
                    graphType: deviceMeasureTypes[measure.measure_name].graphType,
                    type: deviceMeasureTypes[measure.measure_name].type,
                    unit: deviceMeasureTypes[measure.measure_name].unit,
                    makeStat: deviceMeasureTypes[measure.measure_name].stats,
                    objectToAdd: {}
                }
                result[deviceIdKey] = NoiseLevelObserved(result[deviceIdKey], measure, deviceMeasureTypes)
                break;
            }
            case 'SmartMeteringObservation': {
                // --- Récupération de l'unité de mesure
                const unit = getUnit(sensors, deviceId)

                // --- Ajout des informations
                result[deviceId] = SmartMeteringObservation(result[deviceId], measure, savedMeasureForDelta[deviceId], deviceMeasureTypes.graphType, deviceMeasureTypes.unit[unit])
                savedMeasureForDelta[deviceId] = measure["measure_value_double"]
                break
            }
        }
    }))

    return result
}

/**
 * Get threshold from device
 */
 export function getThreshold(devices: any, deviceId: string) {
    const device = devices.find((device: any) => {
        return deviceId.replaceAll('_','').toUpperCase().includes(device.name.replaceAll('_','').toUpperCase())
    })

    if (device) {
        return device.threshold
    } else {
        return null
    }
}

/**
 * Get unit code from device
 */
export function getUnit(devices: any, deviceId: string) {
    const device = devices.find((device: any) => {
        return deviceId.replaceAll('_','').toUpperCase().includes(device.name.replaceAll('_','').toUpperCase())
    })

    if (device) {
        return device.unit
    } else {
        return null
    }
}

/**
 * Get types to add in sql query
 * @returns {string[]}
 */
export function getTypesToSelect(tags: any): any[] {
    const typesToSelect: Array<string> = []

    tags.map((tag: any) => {
        if(tag.typeToSelect) {
            tag.typeToSelect.map((type: string) => {
                if(!typesToSelect.includes(type))
                    typesToSelect.push(type)
            })
        }
    })

    return typesToSelect;
}

function getChildrenLocationTags(tagIds: any, tags: any) {
    const locationTags: any = []

    // --- Pour chaque tagId sélectionné
    tagIds.map((tagId: string) => {

        // --- On récupère le tag avec toutes ses propriétés
        const tagFound = tags.find((tag: { id: string; }) => tag.id === tagId)

        // --- Si le tag possède des tags enfants
        if (tagFound && tagFound.childrenId && tagFound.childrenId.length) {

            // --- On recherche ses tags enfants
            locationTags.push(...getChildrenLocationTags(tagFound.childrenId, tags));

            // --- Et on l'ajoute a la liste des tags Id
            locationTags.push(tagId)
        } else {
            locationTags.push(tagId);
        }
    });

    return locationTags;
}

/**
 * Get devices from tags
 */
export function filterDeviceURN(state: { selectedTags: any; sensors: any; tags: any }) {

    let locationSelectedTags: string[] = [];
    const measureSelectedTags: string[] = [];

    // --- Séparation des tags selon le type
    state.selectedTags
        .map((tag: { id: any; type: string; }) => {
            if (tag.type === "LOCATION") locationSelectedTags.push(tag.id)
            if (tag.type === "MEASURE") measureSelectedTags.push(tag.id)
        });



    // --- Récupération de tous les ids de tag enfants des tags sélectionnés
    locationSelectedTags = getChildrenLocationTags(locationSelectedTags, state.tags)

    // --- Liste de tous les tags uniques des capteurs
    const uniqueLocationTags: any = []
    locationSelectedTags.map((tagId: any) => {
        if (!uniqueLocationTags.filter((allTagId: any) => allTagId === tagId).length) {
            uniqueLocationTags.push(tagId)
        }
    })

    const filteredDevicesURNs: string[] = [];

    // --- Récupération des URNs des capteurs en fonction des tags
    state.sensors
        // --- Filtre sur les tags de type "LOCATION"
        .filter((sensor: { urn: string[]; tags: any[]; }) => {
            if (locationSelectedTags.length) {
                return sensor.tags
                    .map((tag: { id: any; type: string; }) => tag.id)
                    .some((tagId: string) => locationSelectedTags.includes(tagId));
            } else {
                return sensor;
            }
        })
        // --- Filtre sur les tags de type "MEASURE"
        .filter((sensor: { urn: string[]; tags: any[]; }) => {
            if (measureSelectedTags.length) {
                return sensor.tags
                    .map((tag: { id: any; type: string; }) => tag.id)
                    .some((tagId: string) => measureSelectedTags.includes(tagId));
            } else {
                return sensor;
            }
        })
        // --- Récupération de toutes les urns
        .map((sensor: { urn: string[]; tags: any[]; }) => {
            sensor.urn.map((urn: string) => {
                if (!filteredDevicesURNs.includes(urn)) filteredDevicesURNs.push(urn);
            })
        });

    return filteredDevicesURNs;
}
