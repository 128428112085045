<template>
  <div class="stat" v-if="stat">
    <div class="stat-content heading-5" v-html="stat.value + stat.unit"></div>
    <div class="stat-key body-small">{{ $t(`stats.${stat.key}`) }}</div>
  </div>
</template>

<script>
import Stat from '@/domain/stats/Stat';

export default {
    name: "Stat",
    props: {
        stat: Stat
    }
}
</script>
