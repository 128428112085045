import GraphType from "./GraphType";

export default class MeasureKey {
    key: string;
    label: string;
    type: GraphType;

    static from(model: any): MeasureKey {
        const result: MeasureKey = new MeasureKey();

        if (model.key) {
            result.key = model.key;
        }
        if (model.label) {
            result.label = model.label;
        }
        if (model.type) {
            result.type = model.type as GraphType;
        }
        return result;
    }
}
