<template>
  <div id="serfimApp">
    <template v-if="!signedIn">
      <amplify-authenticator></amplify-authenticator>
    </template>
    <template v-if="signedIn">
      <MenuComponent/>
      <notifications position='bottom right' ignoreDuplicates/>
      <div class="content">
        <router-view />
      </div>
    </template>
  </div>
</template>

<script>
import { Auth, Hub } from 'aws-amplify';
import MenuComponent from "@/components/menu/MenuComponent";
import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";
import AmplifyI18n from "amplify-i18n";

export default {
  name: 'App',
  components: {
    MenuComponent,
  },
  data() {
    return {
      signedIn: false
    }
  },
  async beforeCreate() {
    try {
      await Auth.currentAuthenticatedUser()
      this.signedIn = true
    } catch (err) {
      this.signedIn = false
    }

    const listener = (data) => {
      switch (data.payload.event) {
        case 'signIn':
          this.signedIn = true;
          break;
        case 'signUp':
          this.signedIn = true;
          break;
        case 'signOut':
          this.signedIn = false;
          break;
      }
    }

    Hub.listen('auth', listener);
  },
  mounted() {
    const locales = ["en", "fr", "de"]
    AmplifyI18n.configure(locales)
    I18n.setLanguage("fr")
    I18n.putVocabulariesForLanguage("fr", {
      [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: "Avez-vous déjà un compte ?"
    });
  }
}

</script>

<style lang="scss" src="@/style.scss"></style>
