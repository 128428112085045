import moment from "moment"
import Tag from "./Tag";
import TagType from "./TagType";
import { ModelTag } from "@/domain/model/Model";

export default class Measure {
	date: moment.Moment;
	value: number;
	measureKey: string;
	measureUnit: string;
	tags: Tag[];
	sensorId: string;
  hasError: boolean;

    static from(model: any, forceError?: boolean): Measure {
        const result: Measure = new Measure();

        result.date = moment(model.date);
        result.value = model.value;
        result.measureKey = model.key;
        result.measureUnit = model.unit;
        result.tags = [];
        result.sensorId = model.sensorid;
        if (model.tags) {
          model.tags.forEach(it => {
            result.tags.push(Tag.from(it));
          });
        }
        if (!forceError) {
          result.hasError = model.hasError;
        } else {
          result.hasError = true;
        }
        return result;
    }

    static getMeasureLabelByMeasureKey(keys, key) {
        const result = keys.find(it => it.key == key);
        if (result) {
            return result.label;
        } else {
            return key;
        }
    }

    getLastLocationTag() {
        const locationTags = this.tags.filter(it => it.type == TagType.LOCATION)
            .map(it => it.name);
        return locationTags[locationTags.length - 1];
    }
}
