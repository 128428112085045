import { Model } from "../model/Model";
import Stat from "./Stat";

export default class Stats {
    min: Stat;
    max: Stat;
    avg: Stat;

    static from(model: Model): Stats {
        const result = new Stats();
        if (model.stats) {
            const minStat = new Stat();
            minStat.key = 'min';
            minStat.unit = model.unit;
            minStat.value = model.stats.min
            result.min = minStat;

            const maxStat = new Stat();
            maxStat.key = 'max';
            maxStat.unit = model.unit;
            maxStat.value = model.stats.max
            result.max = maxStat;

            const avgStat = new Stat();
            avgStat.key = 'avg';
            avgStat.unit = model.unit;
            avgStat.value = model.stats.avg
            result.avg = avgStat;
        }
        
        
        return result;
    }
}