<template>
  <div class="stats-select">
    <div class="stats">
      <StatComponent :stat=chartStats.max />
      <StatComponent :stat=chartStats.min />
      <StatComponent :stat=chartStats.avg />
    </div>
  </div>
</template>

<script>
import StatComponent from '@/components/stat/StatComponent.vue';

export default {
    name: "Stats",
    props: {
        uuid: String
    },
    components: {
        StatComponent,
    },
    computed: {
        chartStats() {
            return this.$store.getters.statsByChartId(this.uuid);
        },
    }
}
</script>

<style lang="scss">
@import "../../styles/views/chart.scss";
</style>