<template>
  <div class="search-admin" v-cloak>
    <div class="back-home-link" @click="backToHome">
      <font-awesome-icon :icon="['fas', 'chevron-left']" /> <p>{{ $t("backToHome") }}</p>
    </div>

    <div class="page-title">
      {{ $t("search.header") }}
    </div>

    <div class="search-container">
      <div class="search-content tags-list">
        <div class="title">Tag(s) disponible(s)</div>
        <div class="tags-list measure">
          <div class="title">Mesures :</div>
          <div class="tags">
            <template v-if="diffTags(measureTags, orderedSelectedTags).length">
              <BadgeComponent
                  v-for="tag in diffTags(measureTags, orderedSelectedTags)"
                  :key='tag.id'
                  :tag=tag
                  :deletable='false'
                  :is-selecting='true'
                  :tag-parent-name='getTagName(tag.parentId)'
                  @addTag='addTag(tag)'
              />
            </template>
            <template v-else>
              <div class="no-tag">Aucun tag disponible</div>
            </template>
          </div>
        </div>
        <div class="tags-list location">
          <div class="title">Lieux :</div>
          <div class="tags">
            <template v-if="diffTags(locationTags, orderedSelectedTags).length">
              <BadgeComponent
                  v-for="tag in diffTags(locationTags, orderedSelectedTags)"
                  :key='tag.id'
                  :tag=tag
                  :deletable='false'
                  :is-selecting='true'
                  :tag-parent-name='getTagName(tag.parentId)'
                  @addTag='addTag(tag)'
              />
            </template>
            <template v-else>
              <div class="no-tag">Aucun tag disponible</div>
            </template>
          </div>
        </div>
      </div>
      <div class="search-content my-selection">
        <div class="title">Tag(s) sélectionné(s)</div>
        <div class="tags-list">
          <div class="tags">
            <template v-if="orderedSelectedTags.length">
              <BadgeComponent
                  v-for="tag in orderedSelectedTags"
                  :key='tag.id'
                  :tag=tag
                  :deletable='true'
                  :tag-parent-name='getTagName(tag.parentId)'
                  @removeTag='removeTag(tag.id)'
              />
            </template>
            <template v-else>
              <div class="no-tag">Aucun tag sélectionné</div>
            </template>
          </div>
        </div>
      </div>
      <div class="search-action">
        <div class="save button button-full button-lite" @click="validateSearch">RECHERCHER</div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import BadgeComponent from '@/components/tag/BadgeComponent.vue';
import Tag from "@/domain/state/Tag";
import SearchInfo from '@/domain/state/SearchInfo';
import FrontClientService from "@/service/RestFrontClientService";
import Moment from "moment";

export default Vue.extend({
  name: 'Search',
  components: {
    BadgeComponent
  },
  data() {
    return {
      tags: [],
      applicableTags: [],
      selectedTags: []
    }
  },
  computed: {
    /**
     * Get MEASURE tags
     * @returns {*[]}
     */
    measureTags() {
      return this.applicableTags
          .filter(tag => {
            return tag.type == 'MEASURE'
          })
          .sort((itemA, itemB) => (parseInt(itemA.id) > parseInt(itemB.id)) ? 1 : ((parseInt(itemB.id) > parseInt(itemA.id)) ? -1 : 0))
    },
    /**
     * Get LOCATION tags
     * @returns {*[]}
     */
    locationTags() {
      return this.applicableTags
          .filter(tag => {
            return tag.type == 'LOCATION'
          })
          .sort((itemA, itemB) => (parseInt(itemA.id) > parseInt(itemB.id)) ? 1 : ((parseInt(itemB.id) > parseInt(itemA.id)) ? -1 : 0))
    },
    orderedSelectedTags() {
      let selectedTags = this.selectedTags
      return selectedTags.sort((itemA, itemB) => (parseInt(itemA.id) > parseInt(itemB.id)) ? 1 : ((parseInt(itemB.id) > parseInt(itemA.id)) ? -1 : 0))
    },
  },
  methods: {
    /**
     * Back to home action
     */
    backToHome() {
      this.$router.push('/');
    },
    /**
     * Return tag name
     * @param tagId
     * @returns {*|boolean}
     */
    getTagName(tagId) {
      let tagFind = this.tags.find(tag => tag.id == tagId)
      return tagFind ? tagFind.name : false
    },
    /**
     * Add tag to selection
     * @param tagId
     */
    addTag(tag) {
      this.selectedTags.push(tag)
    },
    /**
     * Remove tag from selection
     * @param tagId
     */
    removeTag(tagId) {
      // --- Remove from selection
      this.selectedTags = this.selectedTags.filter(tagFiltered => {
        return tagFiltered.id != tagId
      })
    },
    /**
     * Validate search and go to chart page
     */
    validateSearch() {
      if(!this.selectedTags.length) return // --- TODO gestion erreur
      this.$store.dispatch('searchForResult', new SearchInfo(this.selectedTags));

      // --- Save search to cookies
      const searchSelectionCookie = {
        date: Moment(new Date()).format('DD/MM/YYYY'),
        tags: this.selectedTags
      }

      let searchSelectionCookies = this.$cookies.get("searchSelections") || []
      if(searchSelectionCookies.length >= 5) {
        searchSelectionCookies.shift()
      }
      
      searchSelectionCookies.push(searchSelectionCookie)
      this.$cookies.set("searchSelections", JSON.stringify(searchSelectionCookies), '30d')

      this.$router.push('/chart');
    },
    /**
     * Diff between two tags array
     * @param tags
     * @param sensorTags
     */
    diffTags(tagsA, tagsB) {
      return tagsA.filter(({id: idTagA}) => !tagsB.some(({id: idTagB}) => idTagA === idTagB))
    },
  },
  mounted() {
    // --- Get tags
    if(this.$store.getters.tags.length) {
      this.tags = this.$store.getters.tags
      this.applicableTags = this.$store.getters.tags
    } else {
      FrontClientService.getAllTags().then(response => {
        response.data.forEach(it => {
          this.tags.push(Tag.from(it));
          this.applicableTags.push(Tag.from(it))
        });

        // --- Send to Vuex
        this.$store.dispatch("setTags", this.tags)
      });
    }

    // --- Get selected tags
    if(this.$store.getters.selectedTags.length) {
      this.selectedTags = this.$store.getters.selectedTags
    }

  }
})

</script>

<style lang="scss" scoped>
@import "../styles/views/search.scss";
</style>
