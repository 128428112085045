<template>
  <div class="duration">
    <md-button class="md-raised" 
      v-bind:class="{ active: duration == 'day', inactive: duration != 'day' }" 
      v-on:click="selectDuration('DAY')">{{ $t("date.day") }}
    </md-button>
    <md-button class="md-raised" 
      v-bind:class="{ active: duration == 'week', inactive: duration != 'week' }" 
      v-on:click="selectDuration('WEEK')">{{ $t("date.week") }}
    </md-button>
    <md-button class="md-raised" 
    v-bind:class="{ active: duration == 'month', inactive: duration != 'month' }" 
    v-on:click="selectDuration('MONTH')">{{ $t("date.month") }}
    </md-button>
    <md-button class="md-raised" 
      v-bind:class="{ active: duration == 'year', inactive: duration != 'year' }" 
      v-on:click="selectDuration('YEAR')">{{ $t("date.year") }}
    </md-button>
    <md-button class="refresh-chart"
      v-bind:class="{ active: duration == 'day', inactive: duration != 'day' }" 
      v-on:click="refreshCharts()"><font-awesome-icon :icon="['fas', 'rotate-right']" fixed-width />
    </md-button>
  </div>

</template>

<script>

  export default {
    name: 'DatepickerComponent',
    props: ['setLoader'],
    computed: {
        duration() {
            return this.$store.getters.duration;
        }
    },
    methods:{
        selectDuration(value) {
          this.$store.dispatch('removeSelectedCharts');
          this.$emit('setLoader', true);
          this.$store.dispatch('selectDuration', value);
        },
        refreshCharts() {
          this.$emit('refreshCharts')
        }
    }
  }
</script>

<style lang="scss">
  @import "../../styles/views/chart.scss";
</style>
  