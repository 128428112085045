import ChartInfo from "@/domain/chart/ChartInfo";

import Duration from './Duration';
import moment from 'moment';
import Tag from "./Tag";
import UserInfo from "./UserInfo";
import MeasureKey from "./MeasureKey";
import Sensor from "./Sensor";
import Selection from "./Selection";

export default class State {
    tags: Tag[]
    selectedTags: Tag[]
    sensors: Sensor[]
    selections: Selection[]
    loader: boolean
    selectedDuration: Duration
    startDate: moment.Moment
    endDate: moment.Moment
    charts: ChartInfo[]
    selectedChartUuid: string[]
    expandedMenu: boolean

    //----------------
    availableMeasureKeys: MeasureKey[]
    selectedMeasureKeys: MeasureKey[]
    userInfo: UserInfo

    constructor() {
        this.selectedTags = [];
        this.tags = [];
        this.sensors = [];
        this.selections = [];
        this.loader = true;
        this.selectedDuration = Duration.DAY;
        this.startDate = moment().startOf('day');
        this.endDate = moment().endOf('day');
        this.charts = [];
        this.expandedMenu = false;

        // ------------
        this.selectedChartUuid = [];
        this.availableMeasureKeys = [];
        this.selectedMeasureKeys = [];
        this.userInfo = new UserInfo();
    }
}
