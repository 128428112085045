<template>
  <div class="charts-admin">
    <div class="back-home-link" @click="backToHome">
      <font-awesome-icon :icon="['fas', 'chevron-left']"/>
      <p>{{ $t("backToHome") }}</p>
    </div>

    <div class="selected-tags-container">
      <div class="title">
        <p>Filtres de visualisation <span class="cpt-tags">({{ selectedTags.length }} tag{{ selectedTags.length > 1 ? "s" : "" }})</span></p>
        <div class="tags-actions">
          <md-button class="md-raised update-button"
          @click="backToSearch">MODIFIER
          </md-button>
          <md-button class="md-raised save-button"
          @click="openDialog">SAUVEGARDER
          </md-button>
      </div>
      </div>

      <div class="tags-content">
        <div class="tags">
          <BadgeComponent
              v-for="tag in selectedTags"
              :key='tag.id'
              :tag=tag
              :deletable='false'
              :is-selecting='false'
              :is-chart='true'
              :tag-parent-name='getTagName(tag.parentId)'
          />
        </div>
      </div>
    </div>

    <div class="charts-parameters">
      <DatepickerComponent @setLoader='setLoader' @refreshCharts="refreshCharts" />
      <div class="charts-parameters-right">
        <div class="date-container">
          <div class="date-text">{{ $t("date.datepicker") }}</div>

          <div class="date-range">
            <DateRangeComponent/>
          </div>
        </div>
        <div class="export-container">
          <md-button class="md-raised export-button inactive"
              @click="exportCSV">Export
          </md-button>
        </div>
      </div>
    </div>
    <div class="charts-container">

      <div class="chart-component" v-for="chart of charts" :key='chart.uuid'>
        <div class="square">
          <ChartWithStatsComponent :chartInfo=chart />
        </div>
      </div>

      <div class="no-chart" v-if="!getLoader && !charts.length">
        <div class="no-chart-text">
          {{ $t("chart.noChart") }}
        </div>
      </div>

      <div class="loader" v-if="getLoader">
        <Loader />
      </div>
    </div>

    <ChartFooterComponent/>

    <Modal
        class="selection-dialog-modal"
        v-show="showDialog"
        :show-close-icon="false"
        @close="cancelSelection"
    >
      <template v-slot:header>
        <div class="title">
          {{ $t("chart.modal.title") }}
        </div>
      </template>
      <template v-slot:body>
        <div class="content save-selection">
          <div class="input-container">
            <p>Nom de la sélection</p>
            <input type="text" name="selection-name" id="selection-name" v-model="selectionName">
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="cancel button button-lite no-background" @click="cancelSelection">
          {{ $t("sensorAdmin.tagModal.cancel") }}
        </div>
        <div class="save button button-full button-lite" @click="validateSelection">{{
            $t("sensorAdmin.tagModal.submit")
          }}
        </div>
      </template>
    </Modal>

  </div>
</template>

<script>
import Vue from 'vue';
import ChartWithStatsComponent from '@/components/chart/ChartWithStatsComponent.vue';
import DatepickerComponent from '@/components/date/DatepickerComponent.vue';
import ChartFooterComponent from '@/components/footer/ChartFooterComponent.vue';
import ClientService from '@/service/RestClientService';
import {v4 as uuid} from 'uuid';
import ChartInfo from '@/domain/chart/ChartInfo';
import ChartData from '@/domain/chart/ChartData';
import Measure from '@/domain/state/Measure';
import DateRangeComponent from '@/components/date/DateRangeComponent.vue';
import {measureTypes} from "@/service/ChartsParams";
import FrontClientService from "@/service/RestFrontClientService";
import Tag from "@/domain/state/Tag";
import BadgeComponent from "@/components/tag/BadgeComponent";
import Sensor from "@/domain/state/Sensor";
import Modal from "@/components/Modal";
import Moment from "moment";
import {DateFormat} from "@/service/Constants";
import Loader from "@/components/Loader";
import {getDevicesMeasurements, getTypesToSelect} from "@/service/Utils";
import moment from 'moment';

export default Vue.extend({
  name: 'Chart',
  data() {
    return {
      tags: [],
      devices: [],
      selections: [],
      showDialog: false,
      selectionName: "",

      // --------------------------------------------------------------
      measures: [],
    }
  },
  components: {
    BadgeComponent,
    ChartWithStatsComponent,
    DatepickerComponent,
    DateRangeComponent,
    ChartFooterComponent,
    Modal,
    Loader
  },
  methods: {
    /**
     * Back to home action
     */
    backToHome() {
      this.$store.dispatch('clearTags');
      this.$router.push('/');
    },
    /**
     * Back to search page
     */
    backToSearch() {
      this.$router.push('/search');
    },
    /**
     * Return tag name
     * @param tagId
     * @returns {*|boolean}
     */
    getTagName(tagId) {
      let tagFind = this.tags.find(tag => tag.id == tagId)
      return tagFind ? tagFind.name : false
    },
    /**
     * Show dialog modal
     */
    openDialog() {
      this.showDialog = true
    },
    /**
     * Hide modal
     */
    cancelSelection() {
      this.selectionName = ""
      this.showDialog = false
    },
    /**
     * Send selection to DB
     */
    validateSelection() {
      let idSelection = "1"
      // --- Set id Selection
      if (this.selections.length) {
        // --- Get last max ID
        const selectionsIds = this.selections.map(selection => {
          return parseInt(selection.id)
        })
        idSelection = Math.max.apply(null, selectionsIds) + 1
      }

      // --- Define params
      let params = {
        id: idSelection.toString(),
        name: this.selectionName,
        tags: this.selectedTags,
        creationDate: Moment(new Date()).format(DateFormat.SMALL)
      }

      // --- Add to selections DB
      FrontClientService.postSelection(params).then(response => {
        this.showDialog = false
        this.selectionName = ""
        this.selections.push(params)
      })
    },

    /**
     * Dynamically set the loader
     */
    setLoader(value) {
      this.$store.dispatch('setLoader', value);
    },

    /**
     * Dynamically set charts
     */
    setCharts(charts) {
      this.$store.dispatch('setCharts', charts)
    },

    /**
     * Refresh charts
     */
    async refreshCharts() {
      this.$store.dispatch('removeSelectedCharts');
      this.$store.dispatch('refreshCharts');
    },
    exportCSV() {
      let content = "data:text/csv;charset=utf-8,id,time,value,index\n";

      this.charts.map(chart => {
        if (chart.chartData) {
          chart.chartData.datasets.map(dataset => {
            for (let i = 0; i < dataset.data.length; i++) {
              content += dataset.label + ',';
              content += moment.unix(dataset.timestamp[i]).format(DateFormat.FULL) + ',';
              content += dataset.data[i];
              if (dataset.index && dataset.index[i]) {
                content += ',' + Math.floor(parseFloat(dataset.index[i]));
              }
              content += "\n"
            }
          })
        }
      });
      
      const data = encodeURI(content);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
  },
  computed: {
    /**
     * Return selected tags from store
     * @returns {any}
     */
    selectedTags() {
      return this.$store.getters.selectedTags;
    },
    /**
     * Return SDM selected
     * @returns {*[]}
     */
    getSmartDataModel() {
      let smartDataModels = this.selectedTags
          .filter(tag => {
            return tag.sdm
          })
          .map(tag => {
            if (tag.sdm) {
              return tag.sdm
            }
          })
      return [].concat.apply([], smartDataModels)
    },
    /**
     * Get start date
     * @returns {any}
     */
    startDate() {
      return this.$store.getters.startDate;
    },
    /**
     * Get end date
     * @returns {any}
     */
    endDate() {
      return this.$store.getters.endDate;
    },
    /**
     * Get charts
     * @returns {any}
     */
    charts() {
      return this.$store.getters.charts;
    },
    /**
     * Dynamically retrieve the loader
     * @returns {boolean}
     */
    getLoader() {
      return this.$store.getters.loader
    },

    // ---------------------------------------------------------
    stateMeasureKeys() {
      return this.$store.getters.selectedMeasureKeys;
    },
    availableMeasureKeys() {
      return this.$store.getters.availableMeasureKeys;
    },
    authorityId() {
      return this.$store.getters.authorityId;
    }
  },
  watch: {
    /**
     * Loading chart data
     * @param val
     */
    startDate: async function (val) {
      this.setLoader(true) //Show the loader before data is updated
      this.refreshCharts()
    },
  },
  async mounted() {
    // --- If not selectedTags => back to home
    if (this.selectedTags.length == 0) {
      this.$router.push('/');
      return;
    }

  // --- Get tags
  if (this.$store.getters.tags.length) {
    this.tags = this.$store.getters.tags
  } else {
    await FrontClientService.getAllTags().then(response => {
      response.data.forEach(it => {
        this.tags.push(Tag.from(it))
      });

      // --- Send to Vuex
      this.$store.dispatch("setTags", this.tags)
    });
  }

  // --- Get devices
  if (this.$store.getters.sensors && this.$store.getters.sensors.length) {
    this.devices = this.$store.getters.sensors
  } else {
    await FrontClientService.getAllDevices().then(response => {
      response.data.forEach(it => {
        this.devices.push(Sensor.from(it));
      });

      // --- Send to Vuex
      this.$store.dispatch("setSensors", this.sensors)
    })
  }

  // --- Get selections
  if (this.$store.getters.selections && this.$store.getters.selections.length) {
    this.selections = this.$store.getters.selections
  } else {
    FrontClientService.getAllSelections().then(response => {
      response.data.forEach(it => {
        this.selections.push(Sensor.from(it));
      });

      // --- Send to Vuex
      this.$store.dispatch("setSensors", this.sensors)
    })
  }

  this.refreshCharts()
}
})

</script>

<style lang="scss" scoped>
@import "../styles/views/chart.scss";
</style>
