import MeasureKey from "./MeasureKey";
import Tag from "./Tag";

export default class SearchInfo {
    tags: Tag[];

    constructor(tags: Tag[]) {
        this.tags = tags;
    }
}
