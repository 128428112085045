<template>
  <div class="full-screen-map" >
    <MapChartComponent :sensors=sensors />
  </div>
</template>

<script>
import Vue from 'vue';
import MapChartComponent from '@/components/chart/MapChartComponent.vue';
import restService from '@/service/RestClientService';
import FrontClientService from "@/service/RestFrontClientService";
import Sensor from "@/domain/state/Sensor";

export default Vue.extend({
  name: 'Map',
  components: {
    MapChartComponent
  },
  data() {
    return {
      sensors: [],
    }
  },
  mounted() {
    // --- Get sensors
    if (this.$store.getters.sensors && this.$store.getters.sensors.length) {
      this.sensors = this.$store.getters.sensors
    } else {
      FrontClientService.getAllDevices().then(response => {
        response.data.forEach(it => {
          this.sensors.push(Sensor.from(it));
        });

        // --- Send to Vuex
        this.$store.dispatch("setSensors", this.sensors)
      })
    }
  },
  computed: {
    width() {
      return window.innerWidth;
    },
    height() {
      return window.innerHeight;
    },
    style() {
      return 'width: ' + this.width + 'px; height: ' + this.height + 'px;';
    },
    tags() {
        return this.$store.getters.tags;
    },
    authorityId() {
      return this.$store.getters.authorityId;
    }
  },
})

</script>

<style lang="scss" scoped>
@import "../styles/views/map.scss";
</style>
